
export const SET_DATUM_DETAILS = 'SET_ACCOUNT_DETAILS'
export const SET_DATUM_BUSINESS = 'SET_ACCOUNT_BUSINESS'

export const GET_COUNT_FETCHING = 'GET_ACCOUNT_COUNT_FETCHING'
export const GET_COUNT_SUCCESS = 'GET_ACCOUNT_COUNT_SUCCESS'
export const GET_COUNT_FAILED = 'GET_ACCOUNT_COUNT_FAILED'

export const GET_DATA_FETCHING = 'GET_ACCOUNTS_FETCHING'
export const GET_ALL_DATA_SUCCESS = 'GET_ALL_ACCOUNTS_SUCCESS'
export const GET_PAGED_DATA_SUCCESS = 'GET_PAGED_ACCOUNTS_SUCCESS'
export const GET_DATA_FAILED = 'GET_ACCOUNTS_FAILED'

export const GET_DATUM_FETCHING = 'GET_ACCOUNT_FETCHING'
export const GET_DATUM_SUCCESS = 'GET_ACCOUNT_SUCCESS'
export const GET_DATUM_FAILED = 'GET_ACCOUNT_FAILED'

export const GET_SELECT_LIST_DATA_FETCHING = 'GET_SELECT_LIST_ACCOUNTS_FETCHING'
export const GET_SELECT_LIST_DATA_SUCCESS = 'GET_SELECT_LIST_ACCOUNTS_SUCCESS'
export const GET_SELECT_LIST_DATA_FAILED = 'GET_SELECT_LIST_ACCOUNTS_FAILED'

export const ADD_DATUM_FETCHING = 'ADD_ACCOUNT_FETCHING'
export const ADD_DATUM_SUCCESS = 'ADD_ACCOUNT_SUCCESS'
export const ADD_DATUM_FAILED = 'ADD_ACCOUNT_FAILED'

export const UPDATE_DATUM_FETCHING = 'UPDATE_ACCOUNT_FETCHING'
export const UPDATE_DATUM_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS'
export const UPDATE_DATUM_FAILED = 'UPDATE_ACCOUNT_FAILED'

export const DELETE_DATUM_FETCHING = 'DELETE_ACCOUNT_FETCHING'
export const DELETE_DATUM_SUCCESS = 'DELETE_ACCOUNT_SUCCESS'
export const DELETE_DATUM_FAILED = 'DELETE_ACCOUNT_FAILED'

export const DELETE_DATA_FETCHING = 'DELETE_ACCOUNTS_FETCHING'
export const DELETE_DATA_SUCCESS = 'DELETE_ACCOUNTS_SUCCESS'
export const DELETE_DATA_FAILED = 'DELETE_ACCOUNTS_FAILED'

export const RESET_PASSWORD_FETCHING = 'RESET_ACCOUNT_PASSWORD_FETCHING'
export const RESET_PASSWORD_SUCCESS = 'RESET_ACCOUNT_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILED = 'RESET_ACCOUNT_PASSWORD_FAILED'

