import React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
//import CmtAvatar from '../../../../@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { useSelector } from 'react-redux';
import CmtList from '../../../../@coremat/CmtList';
import { getFormattedDate } from '../../../../@jumbo/utils/dateHelper';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';

const DetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { selectedItem } = useSelector(({ admins }) => admins);

  const { title, lastname, firstname, othernames, email, phone, srole, ispasssetbyadmin, tmpp, dateupdated } = selectedItem;

  const names = `${title} ${lastname} ${firstname} ${!!othernames ? othernames : ''}`;

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.infoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>
                {names}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Names (Surname first)
            </Box>
            <Box ml={2} component="p" fontSize={16}>
            {names}
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Email
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {email}
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Phone Number
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {phone}
            </Box>
          </Box>
        </Box>        
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Role
            </Box>
            <Box ml={2} component="div" fontSize={16}>
              {srole}
            </Box>
          </Box>
        </Box>
        {ispasssetbyadmin && (
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Password (Admin set)
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {tmpp}
            </Box>
          </Box>
        </Box>
        )}        
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Date Updated
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {getFormattedDate(dateupdated, "DD MMM YYYY, hh:mm:ss a")}
            </Box>
          </Box>
        </Box>        
      </Box>
    </Dialog>
  );
};

export default DetailView;

DetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
