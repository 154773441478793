import IntlMessages from '../../../../utils/IntlMessages';
import {
  // AccountCircle,
  // ArrowForward,
  // Category,
  // Chat,
  // CheckCircle,
  // CloudUpload,
  // Colorize,
  // ContactMail,
  Contacts,
  // Domain,
  // DonutSmall,
  // DragIndicator,
  // Edit,
  // EditAttributes,
  // Email,
  // Error,
  // Event,
  Group,
  GroupWork,
  // ImportContacts,
  // InsertChart,
  // LibraryBooks,
  // LocalGroceryStore,
  // LockOutlined,
  // Map,
  // MonetizationOn,
  // NotificationImportant,
  // Notifications,
  // Pages,
  People,
  // ShowChart,
  // Timeline,
  // VpnKey,
  // Widgets,
  ControlPoint,
  List,
  Person
} from '@material-ui/icons';
import React from 'react';

export default  [
    {
        name: <IntlMessages id={'sidebar.firstTimers'} />,
        type: 'collapse',
        roles: ["SU"],
        children: [
        {
            name: <IntlMessages id={'sidebar.firstTimers.list'} />,
            type: 'item',
            icon: <List />,
            roles: ["SU"],
            link: '/members/firsttimers'
        },
        {
            name: <IntlMessages id={'sidebar.firstTimers.new'} />,
            type: 'item',
            icon: <ControlPoint />,
            roles: ["SU"],
            link: '/members/firsttimers/new'
        },
        ],
    },
    {
        name: <IntlMessages id={'sidebar.newConverts'} />,
        type: 'collapse',
        roles: ["SU"],
        children: [
        {
            name: <IntlMessages id={'sidebar.newConverts.list'} />,
            type: 'item',
            icon: <List />,
            roles: ["SU"],
            link: '/members/newcoverts'
        },
        {
            name: <IntlMessages id={'sidebar.newConverts.new'} />,
            type: 'item',
            icon: <ControlPoint />,
            roles: ["SU"],
            link: '/members/newconverts/new'
        },
        ],
    },
    {
        name: <IntlMessages id={'sidebar.members.all'} />,
        type: 'collapse',
        roles: ["SU"],
        children: [
        {
            name: <IntlMessages id={'sidebar.members.list'} />,
            type: 'item',
            icon: <List />,
            roles: ["SU"],
            link: '/members/all'
        },
        {
            name: <IntlMessages id={'sidebar.members.new'} />,
            type: 'item',
            icon: <ControlPoint />,
            roles: ["SU"],
            link: '/members/new'
        },
        ],
    },
    {
        name: <IntlMessages id={'sidebar.members.dependents'} />,
        type: 'item',
        icon: <Group />,
        roles: ["SU"],
        link: '/members/dependents'
    },
    {
        name: <IntlMessages id={'sidebar.members.congregants'} />,
        type: 'item',
        icon: <Group />,
        roles: ["SU"],
        link: '/members/congregants'
    },
    {
        name: <IntlMessages id={'sidebar.members.bonaFide'} />,
        type: 'item',
        icon: <Group />,
        roles: ["SU"],
        link: '/members/bonafides'
    },
    {
        name: <IntlMessages id={'sidebar.members.leaders'} />,
        type: 'item',
        icon: <People />,
        roles: ["SU"],
        link: '/members/leaders'
    },
    {
        name: <IntlMessages id={'sidebar.members.stewards'} />,
        type: 'item',
        icon: <People />,
        roles: ["SU"],
        link: '/members/stewards'
    },
    {
        name: <IntlMessages id={'sidebar.members.ministers'} />,
        type: 'item',
        icon: <People />,
        roles: ["SU"],
        link: '/members/ministers'
    },
    {
        name: <IntlMessages id={'sidebar.members.deacons'} />,
        type: 'item',
        icon: <People />,
        roles: ["SU"],
        link: '/members/deacons'
    },
    {
        name: <IntlMessages id={'sidebar.members.assPastors'} />,
        type: 'item',
        icon: <People />,
        roles: ["SU"],
        link: '/members/pastors/assistants'
    },
    {
        name: <IntlMessages id={'sidebar.members.pastors'} />,
        type: 'item',
        icon: <People />,
        roles: ["SU"],
        link: '/members/pastors'
    },
]
