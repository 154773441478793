import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import { timeFromNow } from '../../../../../@jumbo/utils/dateHelper';
import { Block, CheckCircleOutline, Delete, Edit, Mail, MoreHoriz, Visibility } from '@material-ui/icons';
import CmtDropdownMenu from '../../../../../@coremat/CmtDropdownMenu';   // '../../../../@coremat/CmtDropdownMenu';
import CmtAvatar from '../../../../../@coremat/CmtAvatar';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
//import { sentMailToUser, updateUserStatus } from '../../../../redux/actions/Users';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
  },
}));

const getActions = member => {
  const actions = [
    { action: 'view', label: 'View', icon: <Visibility /> },
    { action: 'edit', label: 'Edit', icon: <Edit /> }
    // { action: 'view', label: 'View', icon: <Visibility /> },
    // { action: 'edit', label: 'Edit', icon: <Edit /> },
  ];

  // if (user.status === 'active') {
  //   actions.push({ action: 'suspend', label: 'Suspend', icon: <Block /> });
  // } else {
  //   actions.push({
  //     action: 'activate',
  //     label: 'Reactivate',
  //     icon: <CheckCircleOutline />,
  //   });
  // }
  //actions.push({ action: 'delete', label: 'Delete', icon: <Delete /> });
  if (!member.maritalstatus === 2) {
    if (!member.spouseid || !member.spousenames) {
      actions.push({ action: 'spouse_add', label: 'Add Spouse', icon: <Edit /> })
    }

    if (member.spouseid || member.spousenames) {
      actions.push({ action: 'spouse_edit', label: 'Edit Spouse', icon: <Edit /> })
      //actions.push({ action: 'spouse_view', label: 'View Spouse', icon: <Visibility /> })
    }
  }

  actions.push({ action: 'residence_edit', label: 'Address', icon: <Edit /> })

  if (member.maritalstatus > 1) {
    actions.push({ action: 'dependents_view', label: 'Dependents', icon: <Edit /> })
  }
// Small Groups
  actions.push({ action: 'departments', label: 'Departments', icon: <Edit /> })
  actions.push({ action: 'lovecenters', label: 'Love Centers', icon: <Edit /> })
  actions.push({ action: 'cells', label: 'Cells', icon: <Edit /> })
  actions.push({ action: 'delete', label: 'Delete', icon: <Delete /> })
//

  // if (!member?.firstbusinesslistingid) {
  //   actions.push({ action: 'business_edit', label: 'New Business', icon: <Edit /> })
  // }
  // else {
  //   actions.push({ action: 'business_edit', label: 'Edit Business', icon: <Edit /> })
  // }
  // // if (member?.firstbusinesslistingid) {
  // //   actions.push({action: 'business_view', label: 'View Business', icon: <Visibility /> });
  // // }

  // if (member.status > 1 && !member.userid) {
  //   actions.push({action: 'account_create', label: 'Create User Profile', icon: <Visibility /> });
  // }  
  // if (member.status > 1 && member.userid && !member.isadmin) {
  //   actions.push({action: 'make_admin', label: 'Make Admin', icon: <Visibility /> });
  // }
  
  // if (member.status === 1 && !member.isnewconvert) { // Todo: disappear if is a new convert
  //   actions.push({action: 'make_n_convert', label: 'Make New Convert', icon: <Visibility /> });
  // }
  
  return actions;
};

const ListRow = ({ row, isSelected, onRowClick, onEdit, onDelete, onView, onBusinessEdit, onBusinessView, onAddress }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onMenuClick = menu => {
    if (menu.action === 'view') {
      onView(row);
    } else if (menu.action === 'edit') {
      onEdit(row);
    // } else if (menu.action === 'business_edit') {
    //   onBusinessEdit(row);
    // } else if (menu.action === 'business_view') {
    // } else if (menu.action === 'email') {
    //   dispatch(sentMailToUser());
    // } else if (menu.action === 'suspend') {
    //   dispatch(updateUserStatus({ id: row.id, status: 'suspended' }));
    // } else if (menu.action === 'activate') {
    //   dispatch(updateUserStatus({ id: row.id, status: 'active' }));
    } else if (menu.action === 'delete') {
      onDelete(row);
    } else if (menu.action === 'residence_edit') {
      onAddress(row);
    }
  };

  const labelId = `enhanced-table-checkbox-${row.id}`;
  const isItemSelected = isSelected(row.id);
  const menuActions = getActions(row);

  return (
    <TableRow
      hover
      onClick={event => onRowClick(event, row.id)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}>
      <TableCell padding="checkbox">
        <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Box display="flex" alignItems="center">
          <Box mr={{ xs: 4, md: 5 }}>
            <CmtAvatar size={40} src={row.profile_pic} alt={row.name} />
          </Box>
          <div>
            <Typography className={classes.titleRoot} component="div" variant="h4">
              {row.memberid}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell>{row.lastname} {row.firstname} {row.othernames}</TableCell>
      <TableCell>{row.sgender}</TableCell>
      <TableCell>{row.smaritalstatus}</TableCell>
      <TableCell>{row.sstatus}</TableCell>
      <TableCell>{row.phone}</TableCell>
            {/* <TableCell>
        {row.status === 'suspended' ? `Suspended by ${row.suspendedBy} (${timeFromNow(row.suspendedAt)})` : row.status}
      </TableCell>
      <TableCell>{timeFromNow(row.lastLoginAt)}</TableCell>
      <TableCell align="right">{row.emailUsage} GB</TableCell> */}
      <TableCell align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu items={menuActions} onItemClick={onMenuClick} TriggerComponent={<MoreHoriz />} />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(ListRow);
