
export const SET_DATUM_DETAILS = 'SET_CURRENCY_DETAILS'

export const GET_DATA_FETCHING = 'GET_CURRENCIES_FETCHING'
export const GET_ALL_DATA_SUCCESS = 'GET_ALL_CURRENCIES_SUCCESS'
export const GET_PAGED_DATA_SUCCESS = 'GET_PAGED_CURRENCIES_SUCCESS'
export const GET_DATA_FAILED = 'GET_CURRENCIES_FAILED'

export const GET_SELECT_LIST_DATA_FETCHING = 'GET_SELECT_LIST_CURRENCIES_FETCHING'
export const GET_SELECT_LIST_DATA_SUCCESS = 'GET_SELECT_LIST_CURRENCIES_SUCCESS'
export const GET_SELECT_LIST_DATA_FAILED = 'GET_SELECT_LIST_CURRENCIES_FAILED'

export const GET_DATUM_FETCHING = 'GET_CURRENCY_FETCHING'
export const GET_DATUM_SUCCESS = 'GET_CURRENCY_SUCCESS'
export const GET_DATUM_FAILED = 'GET_CURRENCY_FAILED'

export const ADD_DATUM_FETCHING = 'ADD_CURRENCY_FETCHING'
export const ADD_DATUM_SUCCESS = 'ADD_CURRENCY_SUCCESS'
export const ADD_DATUM_FAILED = 'ADD_CURRENCY_FAILED'

export const UPDATE_DATUM_FETCHING = 'UPDATE_CURRENCY_FETCHING'
export const UPDATE_DATUM_SUCCESS = 'UPDATE_CURRENCY_SUCCESS'
export const UPDATE_DATUM_FAILED = 'UPDATE_CURRENCY_FAILED'

export const DELETE_DATUM_FETCHING = 'DELETE_CURRENCY_FETCHING'
export const DELETE_DATUM_SUCCESS = 'DELETE_CURRENCY_SUCCESS'
export const DELETE_DATUM_FAILED = 'DELETE_CURRENCY_FAILED'

export const DELETE_DATA_FETCHING = 'DELETE_CURRENCIES_FETCHING'
export const DELETE_DATA_SUCCESS = 'DELETE_CURRENCIES_SUCCESS'
export const DELETE_DATA_FAILED = 'DELETE_CURRENCIES_FAILED'
