
export const SET_DATUM_DETAILS = 'SET_REGION_DETAILS'

export const GET_DATA_FETCHING = 'GET_REGIONS_FETCHING'
export const GET_ALL_DATA_SUCCESS = 'GET_ALL_REGIONS_SUCCESS'
export const GET_PAGED_DATA_SUCCESS = 'GET_PAGED_REGIONS_SUCCESS'
export const GET_DATA_FAILED = 'GET_REGIONS_FAILED'

export const GET_SELECT_LIST_DATA_FETCHING = 'GET_SELECT_LIST_REGIONS_FETCHING'
export const GET_SELECT_LIST_DATA_SUCCESS = 'GET_SELECT_LIST_REGIONS_SUCCESS'
export const GET_SELECT_LIST_DATA_FAILED = 'GET_SELECT_LIST_REGIONS_FAILED'

export const GET_DATUM_FETCHING = 'GET_REGION_FETCHING'
export const GET_DATUM_SUCCESS = 'GET_REGION_SUCCESS'
export const GET_DATUM_FAILED = 'GET_REGION_FAILED'

export const ADD_DATUM_FETCHING = 'ADD_REGION_FETCHING'
export const ADD_DATUM_SUCCESS = 'ADD_REGION_SUCCESS'
export const ADD_DATUM_FAILED = 'ADD_REGION_FAILED'

export const UPDATE_DATUM_FETCHING = 'UPDATE_REGION_FETCHING'
export const UPDATE_DATUM_SUCCESS = 'UPDATE_REGION_SUCCESS'
export const UPDATE_DATUM_FAILED = 'UPDATE_REGION_FAILED'

export const DELETE_DATUM_FETCHING = 'DELETE_REGION_FETCHING'
export const DELETE_DATUM_SUCCESS = 'DELETE_REGION_SUCCESS'
export const DELETE_DATUM_FAILED = 'DELETE_REGION_FAILED'

export const DELETE_DATA_FETCHING = 'DELETE_REGIONS_FETCHING'
export const DELETE_DATA_SUCCESS = 'DELETE_REGIONS_SUCCESS'
export const DELETE_DATA_FAILED = 'DELETE_REGIONS_FAILED'
