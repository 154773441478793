import React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
//import CmtAvatar from '../../../../@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { useSelector } from 'react-redux';
import CmtList from '../../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';


const DetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { selectedItem } = useSelector(({ ftmgmtgroups }) => ftmgmtgroups);

  const { groupnumber, teamlead, members } = selectedItem;

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.infoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>
                {"Group " + groupnumber}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Group Number
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {groupnumber}
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Team Lead
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {teamlead !== null ? (
                <>
                {teamlead?.lastname} {teamlead?.firstname} {teamlead?.othernames}
                </>
              ) : (
                <>
                {"-- NO TEAM LEADER --"}
                </>
              )}
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Members
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {members && members.length > 0 ? (
              <CmtList
                data={members}
                renderRow={(item, index) => (
                  <Box component="p" key={index}>
                    {item.member?.title} {item.member?.lastname} {item.member?.firstname} {item.member?.othernames}
                  </Box>
                )}
              />
            ) : (
              <Typography component="p">
                {"-- NO TEAM MEMBER --"}
              </Typography>
            )}            
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DetailView;

DetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
