import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Header from './Header';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import Contact from './Contact';
import Roles from './Roles';
import Departments from './Departments';
//import Friends from './Friends';
import BioData from './BioData';
import Spouse from './Spouse';
import Dependents from './Dependents';
//import Events from './Events';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetail } from '../profile/store/action';
// import { getUserDetail } from '../../../../redux/actions/ProfileApp';
import makeStyles from '@material-ui/core/styles/makeStyles';
//import UserPhotos from '../../../Dashboards/Intranet/UserPhotos';

const useStyles = makeStyles(() => ({
  pageFull: {
    width: '100%',
  },
  profileSidebar: {
    '@media screen and (min-width: 1280px) and (max-width: 1499px)': {
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
  profileMainContent: {
    '@media screen and (min-width: 1280px) and (max-width: 1499px)': {
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
}));

const Profile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState('about');
  const { authUser } = useSelector(({ auth }) => auth);
  const { item } = useSelector(({ my_profile }) => my_profile);

  useEffect(() => {
    dispatch(getUserDetail());
  }, [dispatch]);

  // const handleTabChange = (event, newValue) => {
  //   setTabValue(newValue);
  // };

  return (
    <React.Fragment>
        <Box className={classes.pageFull}>
          <Header classes={classes} 
                  userDetail={item}
                  authUser={authUser} 
                  // tabValue={tabValue} 
                  // handleTabChange={handleTabChange} 
                  />
       
          <GridContainer>
            <Grid item xs={12} lg={4} className={classes.profileSidebar}>
              <Box mb={6}>
                <Contact userDetail={item} authUser={authUser} />
              </Box>
              <Box mb={6}>
                <Roles authUser={authUser} />
              </Box>
              <Box mb={6}>
                {(item && item.departments) ? (
                  <Departments userDetail={item} />
                ) : (
                  <></>
                )}
              </Box>
            </Grid>
            {item && ( 
            <Grid item xs={12} lg={8} className={classes.profileMainContent}>
              <Box mb={6}>
              {item ? (
                <BioData userDetail={item} />
              ): (
                <></>
              )}
              </Box>
              {item && (item.spouse || item.spousename) && (
              <Box mb={6}>
                <Spouse userDetail={item} />
              </Box>
              )}
              {item && item.dependants && (
              <Box mb={6}>
                <Dependents userDetail={item} />
              </Box>
              )}
              {/* <Events events={userDetail.events} /> */}
            </Grid>          
            )}
          </GridContainer>
        </Box>

    </React.Fragment>
  );
};

export default Profile;
