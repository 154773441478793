import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Divider } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import { makeStyles } from '@material-ui/styles';

import { useDispatch } from 'react-redux';
import { AuhMethods } from '../../../../../../services/auth';
import { CurrentAuthMethod } from '../../../../../constants/AppConstants';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  loginSection: {
    display: 'flex',
    alignItems: 'center',
    '& > .MuiDivider-root': {
      height: 14,
      marginLeft: 8,
      marginRight: 8,
      backgroundColor: theme.palette.text.secondary,
    },
  },
}));

const HeaderLogin = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);

  const onHandleLogout = () => {
    dispatch(AuhMethods[CurrentAuthMethod].onLogout());
  }

  return (
    <div className={classes.root}>
      {/* <Box display="flex" alignItems="center" color="warning.main">
        <CachedIcon fontSize="small" />
        <div className="ml-3">This is primary alert-check it out!</div>
      </Box> */}
      <div className={classes.loginSection}>
      {authUser ? (
        <Button onClick={onHandleLogout} className="Cmt-btn" size="small">
          Logout
        </Button>
      ) : (
        <>
        <Button className="Cmt-btn" size="small">
          Login
        </Button>
        <Divider className="Cmt-divider" orientation="vertical" />
        <Button className="Cmt-btn" size="small">
          Sign up
        </Button>
        </>
        )}
      </div>
    </div>
  );
};

export default HeaderLogin;
