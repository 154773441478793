import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import GridContainer from '../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FormHelperText } from '@material-ui/core'
import AddEditDialog from './AddEditDialog';


const useStyles = makeStyles(theme => ({
    exclusiveRoot: {
      fontSize: 12,
      color: theme.palette.error
    },
    dialogRoot: {
      position: 'relative',
    },
    dialogTitleRoot: {
      '& .MuiTypography-h6': {
        fontSize: 16,
        color: theme.palette.common.dark,
      },
    },
    addressLine: {
      fontSize: 16
    }
  }));


const AddressForm = ({
    address,
    setAddress,
    onSetAddress
}) => {
    const classes = useStyles();

    const [openDialog, setOpenDialog] = useState(false);
    const [addr, setAddr] = useState(address);
    

    useEffect(() => {
      setAddr(address);
    }, [address]);


    const handleUpdateSuccess = (adr) => {
      setAddr(adr);
      setAddress(adr);
    }

    const handleAddSuccess = (adr) => {
      setAddr(adr);
      setAddress(adr);
    }

    const handleCloseDialog = () => {
      setOpenDialog(false);
    };

    if (addr) {
      return (
        <>
        <Box display="flex" flexDirection="row" alignItems='center'>
          <Box flex={1} component='div'>
            <Typography className={classes.addressLine} color="inherit" variant="subtitle1" component="div">
              {addr?.desc}
            </Typography>
            <Typography className={classes.addressLine} color="inherit" variant="subtitle1" component="div">
              {addr?.location?.town}
            </Typography>
            <Typography className={classes.addressLine} color="inherit" variant="subtitle1" component="div">
              {addr?.location?.lga?.state?.name}, {addr?.location?.lga?.state?.country?.name}
            </Typography>
          </Box>
          <Box component='div'>
            <Button color="primary" onClick={() => setOpenDialog(true)}>
              Change Address
            </Button>
          </Box>
        </Box>
        
        {openDialog && <AddEditDialog 
                          open={openDialog} 
                          onCloseDialog={handleCloseDialog} 
                          model={addr}
                          onAddSuccess={handleAddSuccess}
                          onUpdateSuccess={handleUpdateSuccess} />}    
                          </>
      )
    }
    else {
      return (
        <>
        <Box display="flex" flexDirection="column" alignItems='center'>
          <Box component='div'>
            <Button color="primary" onClick={() => setOpenDialog(true)}>
              Add Address
            </Button>
          </Box>
        </Box>

        {openDialog && <AddEditDialog 
                          open={openDialog} 
                          onCloseDialog={handleCloseDialog} 
                          model={addr}
                          onAddSuccess={handleAddSuccess}
                          onUpdateSuccess={handleUpdateSuccess} />}   
                          </>   
      )
    }

}

export default AddressForm;
