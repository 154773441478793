import axios from '../../../../../../../../services/auth/jwt/config';  
import {
  GET_COUNT_FETCHING,
  GET_COUNT_SUCCESS,
  GET_COUNT_FAILED,
  GET_DATA_FETCHING,
  GET_DATA_SUCCESS,
  GET_DATA_FAILED,
} from '../type';


// ** Count all Data
export const countData = (callbackFunc, failedFunc) => {
    return async dispatch => {
      dispatch({
        type: GET_COUNT_FETCHING
      })
  
      try {
        const response = await axios.get('/v1/notifications/count')    //.then(response => {
        
        dispatch({
          type: GET_COUNT_SUCCESS,
          payload: { data: response.data }
         })
         if (callbackFunc) 
          callbackFunc();
      } catch (err) {
        dispatch({
          type: GET_COUNT_FAILED,
          payload: { data: null, error: err }
         })
        if (failedFunc)
          failedFunc(err);
      }
    }
  }
  
  // ** Get all Data
  export const getTopData = (callbackFunc, failedFunc) => {
    return async dispatch => {
      dispatch({
        type: GET_DATA_FETCHING
      })
  
      try {
        const response = await axios.get('/v1/notifications')    //.then(response => {
        
        dispatch({
          type: GET_DATA_SUCCESS,
          payload: { data: response.data }
         })
         if (callbackFunc) 
          callbackFunc();
      } catch (err) {
        dispatch({
          type: GET_DATA_FAILED,
          payload: { data: null, error: err }
         })
        if (failedFunc)
          failedFunc(err);
      }
    }
  }