import React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
//import CmtAvatar from '../../../../@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { useSelector } from 'react-redux';
import CmtList from '../../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import useStyles from './index.style';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import moment from 'moment';


const DetailView = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { selectedItem } = useSelector(({ activitytemplates }) => activitytemplates);

  const { title, sweekday, week, order, score, datecreated } = selectedItem;

  const createdOn = moment(datecreated).format("dddd, MMM Do YYYY, h:mm:ss a");

  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.infoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>
                {title}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Title
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {title}
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Week
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {week}
            </Box>
          </Box>
        </Box>        
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Week Day
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {sweekday}
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Order
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {order}
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Score
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {score}          
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box>
            <Box component="span" fontSize={12} color="text.secondary">
              Date Created
            </Box>
            <Box ml={2} component="p" fontSize={16}>
              {createdOn}
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DetailView;

DetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
