
export const SET_DATUM_DETAILS = 'SET_FT_ACTIVITY_TEMPLATE_DETAILS'

export const GET_COUNT_FETCHING = 'GET_FT_ACTIVITY_TEMPLATE_COUNT_FETCHING'
export const GET_COUNT_SUCCESS = 'GET_FT_ACTIVITY_TEMPLATE_COUNT_SUCCESS'
export const GET_COUNT_FAILED = 'GET_FT_ACTIVITY_TEMPLATE_COUNT_FAILED'

export const GET_DATA_FETCHING = 'GET_FT_ACTIVITY_TEMPLATES_FETCHING'
export const GET_ALL_DATA_SUCCESS = 'GET_ALL_FT_ACTIVITY_TEMPLATES_SUCCESS'
export const GET_PAGED_DATA_SUCCESS = 'GET_PAGED_FT_ACTIVITY_TEMPLATES_SUCCESS'
export const GET_DATA_FAILED = 'GET_FT_ACTIVITY_TEMPLATES_FAILED'

export const GET_SELECT_LIST_DATA_FETCHING = 'GET_SELECT_LIST_FT_ACTIVITY_TEMPLATES_FETCHING'
export const GET_SELECT_LIST_DATA_SUCCESS = 'GET_SELECT_LIST_FT_ACTIVITY_TEMPLATES_SUCCESS'
export const GET_SELECT_LIST_DATA_FAILED = 'GET_SELECT_LIST_FT_ACTIVITY_TEMPLATES_FAILED'

export const GET_SELECT_WEEKS_DATA_FETCHING = 'GET_SELECT_FT_ACTIVITY_WEEKS_FETCHING'
export const GET_SELECT_WEEKS_DATA_SUCCESS = 'GET_SELECT_FT_ACTIVITY_WEEKS_SUCCESS'
export const GET_SELECT_WEEKS_DATA_FAILED = 'GET_SELECT_FT_ACTIVITY_WEEKS_FAILED'

export const GET_SELECT_WEEKDAYS_DATA_FETCHING = 'GET_SELECT_FT_ACTIVITY_WEEKDAYS_FETCHING'
export const GET_SELECT_WEEKDAYS_DATA_SUCCESS = 'GET_SELECT_FT_ACTIVITY_WEEKDAYS_SUCCESS'
export const GET_SELECT_WEEKDAYS_DATA_FAILED = 'GET_SELECT_FT_ACTIVITY_WEEKDAYS_FAILED'

export const GET_DATUM_FETCHING = 'GET_FT_ACTIVITY_TEMPLATE_FETCHING'
export const GET_DATUM_SUCCESS = 'GET_FT_ACTIVITY_TEMPLATE_SUCCESS'
export const GET_DATUM_FAILED = 'GET_FT_ACTIVITY_TEMPLATE_FAILED'

export const ADD_DATUM_FETCHING = 'ADD_FT_ACTIVITY_TEMPLATE_FETCHING'
export const ADD_DATUM_SUCCESS = 'ADD_FT_ACTIVITY_TEMPLATE_SUCCESS'
export const ADD_DATUM_FAILED = 'ADD_FT_ACTIVITY_TEMPLATE_FAILED'

export const UPDATE_DATUM_FETCHING = 'UPDATE_FT_ACTIVITY_TEMPLATE_FETCHING'
export const UPDATE_DATUM_SUCCESS = 'UPDATE_FT_ACTIVITY_TEMPLATE_SUCCESS'
export const UPDATE_DATUM_FAILED = 'UPDATE_FT_ACTIVITY_TEMPLATE_FAILED'

export const DELETE_DATUM_FETCHING = 'DELETE_FT_ACTIVITY_TEMPLATE_FETCHING'
export const DELETE_DATUM_SUCCESS = 'DELETE_FT_ACTIVITY_TEMPLATE_SUCCESS'
export const DELETE_DATUM_FAILED = 'DELETE_FT_ACTIVITY_TEMPLATE_FAILED'

export const DELETE_DATA_FETCHING = 'DELETE_FT_ACTIVITY_TEMPLATES_FETCHING'
export const DELETE_DATA_SUCCESS = 'DELETE_FT_ACTIVITY_TEMPLATES_SUCCESS'
export const DELETE_DATA_FAILED = 'DELETE_FT_ACTIVITY_TEMPLATES_FAILED'
