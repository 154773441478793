import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../../@jumbo/components/Common/formElements/AppTextInput';
// import CmtAvatar from '../../../../@coremat/CmtAvatar';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import CmtList from '../../../../../@coremat/CmtList';   // '../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import AppSelectBox from '../../../../../@jumbo/components/Common/formElements/AppSelectBox';
import { requiredMessage } from '../../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
//import { isValidEmail } from '../../../../@jumbo/utils/commonHelper';
import { getData, addData, updateData } from '../store/action';    // '../../../../redux/actions/Users';
import { getSelectData as countrySelectData } from '../../country/store/action';


const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
}));



const AddEditDialog = ({ open, onCloseDialog, onAddSuccess, onAddFailed, onUpdateSuccess, onUpdateFailed }) => {
  const classes = useStyles();

  const { selectedItem, finish } = useSelector(({ currencies }) => currencies);
  const countryStore = useSelector(state => state.countries);

  const [title, setTitle] = useState(selectedItem?.title);
  const [titleError, setTitleError] = useState('');
  
  const [code, setCode] = useState(selectedItem?.code);
  const [codeError, setCodeError] = useState('');
  
  const [symbol_left, setSymbol_Left] = useState(selectedItem?.symbol_left);
  const [symbol_leftError, setSymbol_LeftError] = useState('');
  
  const [symbol_right, setSymbol_Right] = useState(selectedItem?.symbol_right);
  const [symbol_rightError, setSymbol_RightError] = useState('');

  const [countryId, setCountryId] = useState(selectedItem?.countryid);
  const [countryError, setCountryError] = useState('');

  const [currentCurrency, setCurrentCurrency] = useState(selectedItem);

  const dispatch = useDispatch();

  useEffect(() => {

    setCurrentCurrency(selectedItem);

    dispatch(countrySelectData(() => {
      // do nothing
    }, () => {
      // do nothing
    }))

  }, []);

  const onSubmitClick = () => {
    if (!title) {
      setTitleError(requiredMessage);
    }
    if (!code) {
      setCodeError(requiredMessage);
    }

    if (title && code) {
      onCurrencySave();
    }
  };

  const onCurrencySave = () => {
    const newCurrency = {
      title: title,
      code: code,
      symbol_left: symbol_left,
      symbol_right: symbol_right,
      countryId: countryId
    };

    if (currentCurrency) {
      dispatch(
        updateData({ ...currentCurrency, ...newCurrency }, () => {
          if (onUpdateSuccess)
            onUpdateSuccess();
          onCloseDialog();
        }, (err) => {
          if (onUpdateFailed)
            onUpdateFailed(err);
        }),
      );
    } else {
      dispatch(
        addData(newCurrency, () => {
          if (onAddSuccess)
            onAddSuccess();
          onCloseDialog();
        }, (err) => {
          if (onAddFailed)
            onAddFailed(err);
        }),
      );
    }
  };

  //console.log(selectedItem)

  return (
      <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot} fullWidth={true}>
        <DialogTitle className={classes.dialogTitleRoot}>{currentCurrency ? 'Edit Currency Details' : 'Create New Currency'}</DialogTitle>
        <DialogContent dividers>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={6}>
                <AppTextInput
                  fullWidth
                  variant="outlined"
                  label="Title"
                  value={title}
                  onChange={e => {
                    setTitle(e.target.value);
                    setTitleError('');
                  }}
                  helperText={titleError}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <AppTextInput
                  fullWidth
                  variant="outlined"
                  label="Code"
                  value={code}
                  onChange={e => {
                    setCode(e.target.value);
                    setCodeError('');
                  }}
                  helperText={codeError}
                />
              </Grid>
            </GridContainer>
          </Box>

          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={6}>
                <AppTextInput
                  fullWidth
                  variant="outlined"
                  label="Symbol Left"
                  value={symbol_left}
                  onChange={e => {
                    setSymbol_Left(e.target.value);
                    setSymbol_LeftError('');
                  }}
                  helperText={symbol_leftError}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <AppTextInput
                  fullWidth
                  variant="outlined"
                  label="Symbol Right"
                  value={symbol_right}
                  onChange={e => {
                    setSymbol_Right(e.target.value);
                    setSymbol_RightError('');
                  }}
                  helperText={symbol_rightError}
                />
              </Grid>
            </GridContainer>
          </Box>

          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AppSelectBox
                  fullWidth
                  variant="outlined"
                  label="Country"
                  value={countryId}
                  data={countryStore?.items}
                  valueKey="value"
                  labelKey="label"
                  onChange={e => {
                    setCountryId(e.target.value);           
                    setCountryError('');
                  }}
                  helperText={countryError}
                />
              </Grid>
            </GridContainer>
          </Box>

          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button onClick={onCloseDialog}>Cancel</Button>
            <Box ml={2}>
            <Button disabled={!finish} variant="contained" color="primary" onClick={onSubmitClick}>
                Save
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
  );
};

export default AddEditDialog;

AddEditDialog.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
