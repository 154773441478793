
export const SET_DATUM_DETAILS = 'SET_ADMIN_DETAILS'
export const SET_DATUM_BUSINESS = 'SET_ADMIN_BUSINESS'

export const GET_COUNT_FETCHING = 'GET_ADMIN_COUNT_FETCHING'
export const GET_COUNT_SUCCESS = 'GET_ADMIN_COUNT_SUCCESS'
export const GET_COUNT_FAILED = 'GET_ADMIN_COUNT_FAILED'

export const GET_DATA_FETCHING = 'GET_ADMINS_FETCHING'
export const GET_ALL_DATA_SUCCESS = 'GET_ALL_ADMINS_SUCCESS'
export const GET_PAGED_DATA_SUCCESS = 'GET_PAGED_ADMINS_SUCCESS'
export const GET_DATA_FAILED = 'GET_ADMINS_FAILED'

export const GET_DATUM_FETCHING = 'GET_ADMIN_FETCHING'
export const GET_DATUM_SUCCESS = 'GET_ADMIN_SUCCESS'
export const GET_DATUM_FAILED = 'GET_ADMIN_FAILED'

export const GET_SELECT_LIST_DATA_FETCHING = 'GET_SELECT_LIST_ADMINS_FETCHING'
export const GET_SELECT_LIST_DATA_SUCCESS = 'GET_SELECT_LIST_ADMINS_SUCCESS'
export const GET_SELECT_LIST_DATA_FAILED = 'GET_SELECT_LIST_ADMINS_FAILED'

export const ADD_DATUM_FETCHING = 'ADD_ADMIN_FETCHING'
export const ADD_DATUM_SUCCESS = 'ADD_ADMIN_SUCCESS'
export const ADD_DATUM_FAILED = 'ADD_ADMIN_FAILED'

export const UPDATE_DATUM_FETCHING = 'UPDATE_ADMIN_FETCHING'
export const UPDATE_DATUM_SUCCESS = 'UPDATE_ADMIN_SUCCESS'
export const UPDATE_DATUM_FAILED = 'UPDATE_ADMIN_FAILED'

export const DELETE_DATUM_FETCHING = 'DELETE_ADMIN_FETCHING'
export const DELETE_DATUM_SUCCESS = 'DELETE_ADMIN_SUCCESS'
export const DELETE_DATUM_FAILED = 'DELETE_ADMIN_FAILED'

export const DELETE_DATA_FETCHING = 'DELETE_ADMINS_FETCHING'
export const DELETE_DATA_SUCCESS = 'DELETE_ADMINS_SUCCESS'
export const DELETE_DATA_FAILED = 'DELETE_ADMINS_FAILED'

export const RESET_PASSWORD_FETCHING = 'RESET_PASSWORD_FETCHING'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED'

export const GET_SELECT_ROLES_DATA_FETCHING = 'GET_SELECT_ROLES_DATA_FETCHING'
export const GET_SELECT_ROLES_DATA_SUCCESS = 'GET_SELECT_ROLES_DATA_SUCCESS'
export const GET_SELECT_ROLES_DATA_FAILED = 'GET_SELECT_ROLES_DATA_FAILED'

export const GET_SELECT_ADMIN_MEMBERS_DATA_FETCHING = 'GET_SELECT_ADMIN_MEMBERS_DATA_FETCHING'
export const GET_SELECT_ADMIN_MEMBERS_DATA_SUCCESS = 'GET_SELECT_ADMIN_MEMBERS_DATA_SUCCESS'
export const GET_SELECT_ADMIN_MEMBERS_DATA_FAILED = 'GET_SELECT_ADMIN_MEMBERS_DATA_FAILED'

