
import {
    GET_COUNT_FETCHING,
    GET_COUNT_SUCCESS,
    GET_COUNT_FAILED,
    GET_DATA_FETCHING,
    GET_DATA_SUCCESS,
    GET_DATA_FAILED,
} from '../type';


const initialState = {
    count: 0,
    isCountLoading: false,
    countError: null,
    data: [],
    isFetching: false,
    finish: true,
    error: null,
};


const notificationTray = (state = initialState, action) => {
    switch (action.type) {
      case GET_COUNT_FETCHING:
        return { ...state, isCountLoading: true, countError: null }
      case GET_COUNT_SUCCESS:
        return { ...state, count: action.payload.data, isCountLoading: false, countError: null }
      case GET_COUNT_FAILED:
        return {
          ...state,
          isCountLoading: false,
          countError: action.payload.error
        }
      case GET_DATA_FETCHING:
        return { ...state, isFetching: true, error: null }
      case GET_DATA_SUCCESS:
        return { ...state, data: action.payload.data.msgs, count: action.payload.data.count, isFetching: false, error: null }
      case GET_DATA_FAILED:
        return {
          ...state,
          isFetching: false,
          error: action.payload.error
        }                             
      default:
        return { ...state }
    }
  }
  export default notificationTray
  