import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../../@jumbo/components/Common/formElements/AppTextInput';
// import CmtAvatar from '../../../../@coremat/CmtAvatar';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import CmtList from '../../../../../@coremat/CmtList';   // '../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import AppSelectBox from '../../../../../@jumbo/components/Common/formElements/AppSelectBox';
import { requiredMessage } from '../../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
//import { isValidEmail } from '../../../../@jumbo/utils/commonHelper';
import { getData, addData, updateData } from '../store/action';    // '../../../../redux/actions/Users';
import { getSelectData as stateSelectData } from '../../state/store/action';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
}));



const AddEditDialog = ({ open, onCloseDialog, onAddSuccess, onAddFailed, onUpdateSuccess, onUpdateFailed }) => {
  const classes = useStyles();

  const { selectedItem, finish } = useSelector(({ lgas }) => lgas);
  const stateStore = useSelector(state => state.states);

  const [name, setName] = useState(selectedItem?.name);
  const [nameError, setNameError] = useState('');
  
  const [code, setCode] = useState(selectedItem?.code);
  const [codeError, setCodeError] = useState('');

  const [stateId, setStateId] = useState(selectedItem?.stateid);
  const [stateError, setStateError] = useState('');

  const [currentLga, setCurrentLga] = useState(selectedItem);

  const dispatch = useDispatch();

  useEffect(() => {

    setCurrentLga(selectedItem);

    dispatch(stateSelectData(() => {
      // do nothing
    }, () => {
      // do nothing
    }))

  }, []);

  const onSubmitClick = () => {
    if (!name) {
      setNameError(requiredMessage);
    }
    if (!code) {
      setCodeError(requiredMessage);
    }
    if (!stateId) {
      setStateError(requiredMessage);
    }
    if (name && code && stateId) {
      onLgaSave();
    }
  };

  const onLgaSave = () => {
    const newLga = {
      name: name,
      code: code,
      stateId: stateId
    };

    if (currentLga) {
      dispatch(
        updateData({ ...currentLga, ...newLga }, () => {
          if (onUpdateSuccess)
            onUpdateSuccess();
          onCloseDialog();
        }, (err) => {
          if (onUpdateFailed)
            onUpdateFailed(err);
        }),
      );
    } else {
      dispatch(
        addData(newLga, () => {
          if (onAddSuccess)
            onAddSuccess();
          onCloseDialog();
        }, (err) => {
          if (onAddFailed)
            onAddFailed(err);
        }),
      );
    }
  };

  //console.log(selectedItem)

  return (
      <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot} fullWidth={true}>
        <DialogTitle className={classes.dialogTitleRoot}>{currentLga ? 'Edit LGA Details' : 'Create New LGA'}</DialogTitle>
        <DialogContent dividers>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={9}>
                <AppTextInput
                  fullWidth
                  variant="outlined"
                  label="Name"
                  value={name}
                  onChange={e => {
                    setName(e.target.value);
                    setNameError('');
                  }}
                  helperText={nameError}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <AppTextInput
                  fullWidth
                  variant="outlined"
                  label="Code"
                  value={code}
                  onChange={e => {
                    setCode(e.target.value);
                    setCodeError('');
                  }}
                  helperText={codeError}
                />
              </Grid>            
            </GridContainer>
          </Box>

          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AppSelectBox
                  fullWidth
                  variant="outlined"
                  label="State"
                  value={stateId}
                  data={stateStore?.items}
                  valueKey="value"
                  labelKey="label"
                  onChange={e => {
                    setStateId(e.target.value);
                  }}
                  helperText={stateError}
                />
              </Grid>
            </GridContainer>
          </Box>

          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button onClick={onCloseDialog}>Cancel</Button>
            <Box ml={2}>
            <Button disabled={!finish} variant="contained" color="primary" onClick={onSubmitClick}>
                Save
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
  );
};

export default AddEditDialog;

AddEditDialog.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
