import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Common from './Common';
import Dashboard from './Dashboard';
//import TaskList from './TaskList';
//import MailApp from './MailApp';
//import Chat from './Chat';
//import ContactApp from './ContactApp';
//import ProfileApp from './ProfileApp';
//import WallApp from './WallApp';
import Auth from './Auth';
//import Users from './Users';

import notificationTray from '../../../src/@jumbo/components/AppLayout/partials/Header/HeaderNotifications/store/reducer';

import countries from '../../routes/app/settings/country/store/reducer';
import currencies from '../../routes/app/settings/currency/store/reducer';
import states from '../../routes/app/settings/state/store/reducer';
import lgas from '../../routes/app/settings/lga/store/reducer';
import locations from '../../routes/app/settings/location/store/reducer';
import countryRegions from '../../routes/app/settings/region/store/reducer';

import my_profile from '../../routes/app/my/profile/store/reducer';

import accounts from '../../routes/app/account/store/reducer';
import admins from '../../routes/app/admin/store/reducer';

import members from '../../routes/app/members/members/store/reducer';
import firstTimers from '../../routes/app/members/firstTimers/store/reducer';
import newConverts from '../../routes/app/members/newConverts/store/reducer';

import programs from '../../routes/app/management/program/store/reducer';
import naturalGroups from '../../routes/app/management/naturalgroup/store/reducer';
import departments from '../../routes/app/management/department/store/reducer';
import districts from '../../routes/app/management/district/store/reducer';
import zones from '../../routes/app/management/zone/store/reducer';
import cells from '../../routes/app/management/cell/store/reducer';
import loveCenters from '../../routes/app/management/lovecenter/store/reducer';
import activitytemplates from '../../routes/app/management/activitytemplate/store/reducer';
import ftmgmtgroups from '../../routes/app/management/ftmgmtgroup/store/reducer';

import jobCategories from '../../routes/app/advancement/jobCategory/store/reducer';
import jobCompanies from '../../routes/app/advancement/jobCompany/store/reducer';
import jobTags from '../../routes/app/advancement/jobTag/store/reducer';
import jobs from '../../routes/app/advancement/job/store/reducer';
import businesses from '../../routes/app/advancement/business/store/reducer';

import currentweekcard from '../../routes/app/dashboards/Components/CurrentWeekCounterCard/store/reducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    //taskList: TaskList,
    dashboard: Dashboard,
    currentweek: currentweekcard,

    //mailApp: MailApp,
    //chat: Chat,
    auth: Auth,
    //contactApp: ContactApp,
    //profileApp: ProfileApp,
    //wallApp: WallApp,
    //usersReducer: Users,

    notificationTray,

    my_profile,
    countries,
    currencies,
    states,
    lgas,
    locations,
    countryRegions,
    accounts,
    admins,
    members,
    firstTimers,
    newConverts,
    programs,
    naturalGroups,
    departments,
    districts,
    zones,
    cells,
    loveCenters,
    jobCategories,
    jobCompanies,
    jobTags,
    jobs,
    businesses,
    activitytemplates,
    ftmgmtgroups,
  });
