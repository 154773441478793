
export const SET_DATUM_DETAILS = 'SET_COUNTRY_DETAILS'

export const GET_DATA_FETCHING = 'GET_COUNTRIES_FETCHING'
export const GET_ALL_DATA_SUCCESS = 'GET_ALL_COUNTRIES_SUCCESS'
export const GET_PAGED_DATA_SUCCESS = 'GET_PAGED_COUNTRIES_SUCCESS'
export const GET_DATA_FAILED = 'GET_COUNTRIES_FAILED'

export const GET_SELECT_LIST_DATA_FETCHING = 'GET_SELECT_LIST_COUNTRIES_FETCHING'
export const GET_SELECT_LIST_DATA_SUCCESS = 'GET_SELECT_LIST_COUNTRIES_SUCCESS'
export const GET_SELECT_LIST_DATA_FAILED = 'GET_SELECT_LIST_COUNTRIES_FAILED'

export const GET_DATUM_FETCHING = 'GET_COUNTRY_FETCHING'
export const GET_DATUM_SUCCESS = 'GET_COUNTRY_SUCCESS'
export const GET_DATUM_FAILED = 'GET_COUNTRY_FAILED'

export const ADD_DATUM_FETCHING = 'ADD_COUNTRY_FETCHING'
export const ADD_DATUM_SUCCESS = 'ADD_COUNTRY_SUCCESS'
export const ADD_DATUM_FAILED = 'ADD_COUNTRY_FAILED'

export const UPDATE_DATUM_FETCHING = 'UPDATE_COUNTRY_FETCHING'
export const UPDATE_DATUM_SUCCESS = 'UPDATE_COUNTRY_SUCCESS'
export const UPDATE_DATUM_FAILED = 'UPDATE_COUNTRY_FAILED'

export const DELETE_DATUM_FETCHING = 'DELETE_COUNTRY_FETCHING'
export const DELETE_DATUM_SUCCESS = 'DELETE_COUNTRY_SUCCESS'
export const DELETE_DATUM_FAILED = 'DELETE_COUNTRY_FAILED'

export const DELETE_DATA_FETCHING = 'DELETE_COUNTRIES_FETCHING'
export const DELETE_DATA_SUCCESS = 'DELETE_COUNTRIES_SUCCESS'
export const DELETE_DATA_FAILED = 'DELETE_COUNTRIES_FAILED'
