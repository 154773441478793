import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../../@jumbo/components/Common/formElements/AppTextInput';
// import CmtAvatar from '../../../../@coremat/CmtAvatar';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import CmtList from '../../../../../@coremat/CmtList';   // '../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import AppSelectBox from '../../../../../@jumbo/components/Common/formElements/AppSelectBox';
import { requiredMessage } from '../../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
//import { isValidEmail } from '../../../../@jumbo/utils/commonHelper';
import { 
  getData, 
  addData, 
  updateData,
  getSelectWeeksData,
  getSelectWeekDaysData
} from '../store/action';   

const useStyles = makeStyles(theme => ({
  exclusiveRoot: {
    fontSize: 12,
    color: theme.palette.error
  },
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
}));



const AddEditDialog = ({ open, onCloseDialog, onAddSuccess, onAddFailed, onUpdateSuccess, onUpdateFailed }) => {
  const classes = useStyles();

  const { selectedItem, weeks, weekDays, finish } = useSelector(({ activitytemplates }) => activitytemplates);

  const [title, setTitle] = useState(selectedItem?.title);
  const [titleError, setTitleError] = useState('');
  
  const [score, setScore] = useState(selectedItem?.score);
  const [scoreError, setScoreError] = useState('');
  
  const [order, setOrder] = useState(selectedItem?.order);
  const [orderError, setOrderError] = useState('');
    
  const [week, setWeek] = useState(selectedItem?.week);
  const [weekError, setWeekError] = useState('');

  const [weekday, setWeekDay] = useState(selectedItem?.weekday);
  const [weekdayError, setWeekDayError] = useState('');

  const [exclusiveError, setExclusiveError] = useState('');

  const [currentTemplate, setCurrentTemplate] = useState(selectedItem);

  const dispatch = useDispatch();

  useEffect(() => {

    setCurrentTemplate(selectedItem);

    dispatch(getSelectWeeksData(() => {
      // do nothing
    }, () => {
      // do nothing
    }))

    dispatch(getSelectWeekDaysData(() => {
      // do nothing
    }, () => {
      // do nothing
    }))

  }, []);

  const onSubmitClick = () => {
    if (!title) {
      setTitleError(requiredMessage);
    }
    if (!score) {
      setScoreError(requiredMessage);
    }
    if (!order) {
      setOrderError(requiredMessage);
    }
    if (!week) {
      setWeekError(requiredMessage);
    }
    if (!weekday) {
      setWeekDayError(requiredMessage);
    }
    //console.log(headId, secretaryId, exclusiveError, !exclusiveError)
    if (title && score && order && week && weekday && !exclusiveError) {
      onTemplateSave();
    }
  };

  const onTemplateSave = () => {

    const newTemplate = {
      title: title,
      order: parseInt(order),
      week: parseInt(week),
      weekday: parseInt(weekday),
      score: parseFloat(score)
    };

    if (currentTemplate) {
      dispatch(
        updateData({ ...currentTemplate, ...newTemplate }, () => {
          if (onUpdateSuccess)
            onUpdateSuccess();
          onCloseDialog();
        }, (err) => {
          if (onUpdateFailed)
            onUpdateFailed(err);
        }),
      );
    } else {
      dispatch(
        addData(newTemplate, () => {
          if (onAddSuccess)
            onAddSuccess();
          onCloseDialog();
        }, (err) => {
          if (onAddFailed)
            onAddFailed(err);
        }),
      );
    }
  };

  return (
      <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot} fullWidth={true}>
        <DialogTitle className={classes.dialogTitleRoot}>{currentTemplate ? 'Edit Activity Template' : 'Create New Activity Template'}</DialogTitle>
        <DialogContent dividers>
        {exclusiveError  && (
          <Box display="flex" alignItems="center" mb={{ xs: 6, md: 5 }}>
            <Typography className={classes.exclusiveRoot}>
              {title}
            </Typography>
          </Box>          
        )}
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AppTextInput
                  fullWidth
                  variant="outlined"
                  label="Title"
                  value={title}
                  onChange={e => {
                    setTitle(e.target.value);
                    setTitleError('');
                  }}
                  helperText={titleError}
                />
              </Grid>
            </GridContainer>
          </Box>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
              <AppSelectBox
                  fullWidth
                  variant="outlined"
                  label="Week"
                  value={week}
                  data={weeks}
                  valueKey="value"
                  labelKey="label"
                  onChange={e => {
                    setWeek(e.target.value);
                    setWeekError('');
                  }}
                  helperText={weekError}
                />
              </Grid>
            </GridContainer>
          </Box>             
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
              <AppSelectBox
                  fullWidth
                  variant="outlined"
                  label="Week Day"
                  value={weekday}
                  data={weekDays}
                  valueKey="value"
                  labelKey="label"
                  onChange={e => {
                    setWeekDay(e.target.value);
                    setWeekDayError('');
                  }}
                  helperText={weekdayError}
                />
              </Grid>
            </GridContainer>
          </Box>                  
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AppTextInput
                  fullWidth
                  variant="outlined"
                  type="number"
                  label="Order"
                  value={order}
                  onChange={e => {
                    setOrder(e.target.value);
                    setOrderError('');
                  }}
                  helperText={orderError}
                />
              </Grid>
            </GridContainer>
          </Box>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AppTextInput
                  fullWidth
                  variant="outlined"
                  label="Score"
                  value={score}
                  onChange={e => {
                    setScore(e.target.value);
                    setScoreError('');
                  }}
                  helperText={orderError}
                />
              </Grid>
            </GridContainer>
          </Box>   

          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button onClick={onCloseDialog}>Cancel</Button>
            <Box ml={2}>
            <Button disabled={!finish} variant="contained" color="primary" onClick={onSubmitClick}>
                Save
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
  );
};

export default AddEditDialog;

AddEditDialog.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
