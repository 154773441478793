
export const SET_DATUM_DETAILS = 'SET_BUSINESS_DETAILS'

export const GET_COUNT_FETCHING = 'GET_BUSINESS_COUNT_FETCHING'
export const GET_COUNT_SUCCESS = 'GET_BUSINESS_COUNT_SUCCESS'
export const GET_COUNT_FAILED = 'GET_BUSINESS_COUNT_FAILED'

export const GET_DATA_FETCHING = 'GET_BUSINESSES_FETCHING'
export const GET_ALL_DATA_SUCCESS = 'GET_ALL_BUSINESSES_SUCCESS'
export const GET_PAGED_DATA_SUCCESS = 'GET_PAGED_BUSINESSES_SUCCESS'
export const GET_DATA_FAILED = 'GET_BUSINESSES_FAILED'

export const GET_SELECT_LIST_DATA_FETCHING = 'GET_SELECT_LIST_BUSINESSES_FETCHING'
export const GET_SELECT_LIST_DATA_SUCCESS = 'GET_SELECT_LIST_BUSINESSES_SUCCESS'
export const GET_SELECT_LIST_DATA_FAILED = 'GET_SELECT_LIST_BUSINESSES_FAILED'

export const GET_DATUM_FETCHING = 'GET_BUSINESS_FETCHING'
export const GET_DATUM_SUCCESS = 'GET_BUSINESS_SUCCESS'
export const GET_DATUM_FAILED = 'GET_BUSINESS_FAILED'

export const FETCH_DATUM_FETCHING = 'FETCH_BUSINESS_FETCHING'
export const FETCH_DATUM_SUCCESS = 'FETCH_BUSINESS_SUCCESS'
export const FETCH_DATUM_FAILED = 'FETCH_BUSINESS_FAILED'

export const ADD_DATUM_FETCHING = 'ADD_BUSINESS_FETCHING'
export const ADD_DATUM_SUCCESS = 'ADD_BUSINESS_SUCCESS'
export const ADD_DATUM_FAILED = 'ADD_BUSINESS_FAILED'

export const UPDATE_DATUM_FETCHING = 'UPDATE_BUSINESS_FETCHING'
export const UPDATE_DATUM_SUCCESS = 'UPDATE_BUSINESS_SUCCESS'
export const UPDATE_DATUM_FAILED = 'UPDATE_BUSINESS_FAILED'

export const DELETE_DATUM_FETCHING = 'DELETE_BUSINESS_FETCHING'
export const DELETE_DATUM_SUCCESS = 'DELETE_BUSINESS_SUCCESS'
export const DELETE_DATUM_FAILED = 'DELETE_BUSINESS_FAILED'

export const DELETE_DATA_FETCHING = 'DELETE_BUSINESSES_FETCHING'
export const DELETE_DATA_SUCCESS = 'DELETE_BUSINESSES_SUCCESS'
export const DELETE_DATA_FAILED = 'DELETE_BUSINESSES_FAILED'
