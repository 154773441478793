import IntlMessages from '../../../../utils/IntlMessages';
import {
  // AccountCircle,
  // ArrowForward,
  // Category,
  // Chat,
  // CheckCircle,
  // CloudUpload,
  // Colorize,
  // ContactMail,
  Contacts,
  Domain,
  DonutSmall,
  // DragIndicator,
  // Edit,
  // EditAttributes,
  // Email,
  // Error,
  // Event,
  Group,
  GroupWork,
  // ImportContacts,
  // InsertChart,
  // LibraryBooks,
  // LocalGroceryStore,
  // LockOutlined,
  Map,
  // MonetizationOn,
  // NotificationImportant,
  // Notifications,
  Pages,
  People,
  ShowChart,
  Timeline,
  // VpnKey,
  Widgets,
  ControlPoint,
  List,
  Person,
  Money
} from '@material-ui/icons';
import React from 'react';

export default  [
  {
    name: <IntlMessages id={'sidebar.programs'} />,
    type: 'item',
    icon: <Pages />,
    roles: ["SU"],
    link: '/management/programs'
  },
  {
    name: <IntlMessages id={'sidebar.naturalGroups'} />,
    type: 'item',
    icon: <People />,
    roles: ["SU"],
    link: '/management/naturalgroups'
  },
  {
    name: <IntlMessages id={'sidebar.departments'} />,
    type: 'item',
    icon: <Group />,
    roles: ["SU"],
    link: '/management/departments'
  },
  {
    name: <IntlMessages id={'sidebar.districts'} />,
    type: 'item',
    icon: <Contacts />,
    roles: ["SU"],
    link: '/management/districts'
  },
  {
    name: <IntlMessages id={'sidebar.zones'} />,
    type: 'item',
    icon: <ShowChart />,
    roles: ["SU"],
    link: '/management/zones'
  },
  {
    name: <IntlMessages id={'sidebar.loveCenters'} />,
    type: 'item',
    icon: <Person />,
    roles: ["SU"],
    link: '/management/loveCenters'
  },
  {
    name: <IntlMessages id={'sidebar.activity.templates.list'} />,
    type: 'item',
    icon: <Person />,
    roles: ["SU"],
    link: '/management/activitytemplates'
  }, 
  {
    name: <IntlMessages id={'sidebar.firstTimers.management.group.list'} />,
    type: 'item',
    icon: <Person />,
    roles: ["SU"],
    link: '/management/ftmgmtvrp'
  },     
  {
    name: <IntlMessages id={'sidebar.cells'} />,
    type: 'item',
    icon: <GroupWork />,
    roles: ["SU"],
    link: '/management/cells'
  },
  {
    name: <IntlMessages id={'sidebar.accounts.list'} />,
    type: 'item',
    icon: <People />,
    roles: ["SU"],
    link: '/management/accounts'
  },
  {
    name: <IntlMessages id={'sidebar.settings'} />,
    type: 'collapse',
    roles: ["SU"],
    children: [
      {
        name: <IntlMessages id={'sidebar.country.list'} />,
        type: 'item',
        icon: <Domain />,
        roles: ["SU"],
        link: '/settings/countries'
      },
      {
        name: <IntlMessages id={'sidebar.currency.list'} />,
        type: 'item',
        icon: <Money />,
        roles: ["SU"],
        link: '/settings/currencies'
      },
      {
        name: <IntlMessages id={'sidebar.region.list'} />,
        type: 'item',
        icon: <Map />,
        roles: ["SU"],
        link: '/settings/regions'
      },
      {
        name: <IntlMessages id={'sidebar.state.list'} />,
        type: 'item',
        icon: <DonutSmall />,
        roles: ["SU"],
        link: '/settings/states'
      },
      {
        name: <IntlMessages id={'sidebar.lga.list'} />,
        type: 'item',
        icon: <Widgets />,
        roles: ["SU"],
        link: '/settings/lgas'
      },
      {
        name: <IntlMessages id={'sidebar.location.list'} />,
        type: 'item',
        icon: <Timeline />,
        roles: ["SU"],
        link: '/settings/locations'
      },
    ],
  },  
]

