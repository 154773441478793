import {
    GET_USER_DETAIL_FETCHING, 
    GET_USER_DETAIL_SUCCESS, 
    GET_USER_DETAIL_FAILED 
  } from '../type'
  

const initialState = {
    params: {},
    item: null,
    isFetching: false,
    finish: true,   // for add, update, delete
    error: null
  }
  
  const my_profile = (state = initialState, action) => {
    switch (action.type) {
      case GET_USER_DETAIL_FETCHING:
        return { ...state, isFetching: true, error: null }
      case GET_USER_DETAIL_SUCCESS:
        return { ...state, item: action.payload.item, isFetching: false, error: null }
      case GET_USER_DETAIL_FAILED:
        return {
          ...state,
          isFetching: false,
          error: action.payload.error
        }
      default:
        return { ...state }
    }
  }
  export default my_profile
  