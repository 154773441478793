
export const SET_DATUM_DETAILS = 'SET_FIRST_TIMER_DETAILS'

export const GET_COUNT_FETCHING = 'GET_FIRST_TIMER_COUNT_FETCHING'
export const GET_COUNT_SUCCESS = 'GET_FIRST_TIMER_COUNT_SUCCESS'
export const GET_COUNT_FAILED = 'GET_FIRST_TIMER_COUNT_FAILED'

export const GET_DATA_FETCHING = 'GET_FIRST_TIMERS_FETCHING'
export const GET_ALL_DATA_SUCCESS = 'GET_ALL_FIRST_TIMERS_SUCCESS'
export const GET_PAGED_DATA_SUCCESS = 'GET_PAGED_FIRST_TIMERS_SUCCESS'
export const GET_DATA_FAILED = 'GET_FIRST_TIMERS_FAILED'

export const GET_SELECT_LIST_DATA_FETCHING = 'GET_SELECT_LIST_FIRST_TIMERS_FETCHING'
export const GET_SELECT_LIST_DATA_SUCCESS = 'GET_SELECT_LIST_FIRST_TIMERS_SUCCESS'
export const GET_SELECT_LIST_DATA_FAILED = 'GET_SELECT_LIST_FIRST_TIMERS_FAILED'

export const GET_SELECT_TITLE_DATA_FETCHING = 'GET_SELECT_TITLE_FIRST_TIMERS_FETCHING'
export const GET_SELECT_TITLE_DATA_SUCCESS = 'GET_SELECT_TITLE_FIRST_TIMERS_SUCCESS'
export const GET_SELECT_TITLE_DATA_FAILED = 'GET_SELECT_TITLE_FIRST_TIMERS_FAILED'

export const GET_SELECT_GENDER_DATA_FETCHING = 'GET_SELECT_GENDER_FIRST_TIMERS_FETCHING'
export const GET_SELECT_GENDER_DATA_SUCCESS = 'GET_SELECT_GENDER_FIRST_TIMERS_SUCCESS'
export const GET_SELECT_GENDER_DATA_FAILED = 'GET_SELECT_GENDER_FIRST_TIMERS_FAILED'

export const GET_SELECT_MARITAL_STATUS_DATA_FETCHING = 'GET_SELECT_MARITAL_STATUS_FIRST_TIMERS_FETCHING'
export const GET_SELECT_MARITAL_STATUS_DATA_SUCCESS = 'GET_SELECT_MARITAL_STATUS_FIRST_TIMERS_SUCCESS'
export const GET_SELECT_MARITAL_STATUS_DATA_FAILED = 'GET_SELECT_MARITAL_STATUS_FIRST_TIMERS_FAILED'

export const GET_SELECT_MEMBERSHIP_STATUS_DATA_FETCHING = 'GET_SELECT_MEMBERSHIP_STATUS_FIRST_TIMERS_FETCHING'
export const GET_SELECT_MEMBERSHIP_STATUS_DATA_SUCCESS = 'GET_SELECT_MEMBERSHIP_STATUS_FIRST_TIMERS_SUCCESS'
export const GET_SELECT_MEMBERSHIP_STATUS_DATA_FAILED = 'GET_SELECT_MEMBERSHIP_STATUS_FIRST_TIMERS_FAILED'

export const GET_SELECT_EDUCATION_LEVEL_DATA_FETCHING = 'GET_SELECT_EDUCATION_LEVEL_FIRST_TIMERS_FETCHING'
export const GET_SELECT_EDUCATION_LEVEL_DATA_SUCCESS = 'GET_SELECT_EDUCATION_LEVEL_FIRST_TIMERS_SUCCESS'
export const GET_SELECT_EDUCATION_LEVEL_DATA_FAILED = 'GET_SELECT_EDUCATION_LEVEL_FIRST_TIMERS_FAILED'

export const GET_SELECT_MEMBERSHIP_JUSTIFICATION_DATA_FETCHING = 'GET_SELECT_MEMBERSHIP_JUSTIFICATION_FIRST_TIMERS_FETCHING'
export const GET_SELECT_MEMBERSHIP_JUSTIFICATION_DATA_SUCCESS = 'GET_SELECT_MEMBERSHIP_JUSTIFICATION_FIRST_TIMERS_SUCCESS'
export const GET_SELECT_MEMBERSHIP_JUSTIFICATION_DATA_FAILED = 'GET_SELECT_MEMBERSHIP_JUSTIFICATION_FIRST_TIMERS_FAILED'

export const GET_SELECT_COUNTRY_CODES_DATA_FETCHING = 'GET_SELECT_COUNTRY_CODES_FIRST_TIMERS_FETCHING'
export const GET_SELECT_COUNTRY_CODES_DATA_SUCCESS = 'GET_SELECT_COUNTRY_CODES_FIRST_TIMERS_SUCCESS'
export const GET_SELECT_COUNTRY_CODES_DATA_FAILED = 'GET_SELECT_COUNTRY_CODES_FIRST_TIMERS_FAILED'

export const GET_DATUM_FETCHING = 'GET_FIRST_TIMER_FETCHING'
export const GET_DATUM_SUCCESS = 'GET_FIRST_TIMER_SUCCESS'
export const GET_DATUM_FAILED = 'GET_FIRST_TIMER_FAILED'

export const ADD_DATUM_FETCHING = 'ADD_FIRST_TIMER_FETCHING'
export const ADD_DATUM_SUCCESS = 'ADD_FIRST_TIMER_SUCCESS'
export const ADD_DATUM_FAILED = 'ADD_FIRST_TIMER_FAILED'

export const UPDATE_DATUM_FETCHING = 'UPDATE_FIRST_TIMER_FETCHING'
export const UPDATE_DATUM_SUCCESS = 'UPDATE_FIRST_TIMER_SUCCESS'
export const UPDATE_DATUM_FAILED = 'UPDATE_FIRST_TIMER_FAILED'

export const DELETE_DATUM_FETCHING = 'DELETE_FIRST_TIMER_FETCHING'
export const DELETE_DATUM_SUCCESS = 'DELETE_FIRST_TIMER_SUCCESS'
export const DELETE_DATUM_FAILED = 'DELETE_FIRST_TIMER_FAILED'

export const DELETE_DATA_FETCHING = 'DELETE_FIRST_TIMERS_FETCHING'
export const DELETE_DATA_SUCCESS = 'DELETE_FIRST_TIMERS_SUCCESS'
export const DELETE_DATA_FAILED = 'DELETE_FIRST_TIMERS_FAILED'
