
export const SET_DATUM_DETAILS = 'SET_MEMBER_DETAILS'
export const SET_DATUM_BUSINESS = 'SET_MEMBER_BUSINESS'
export const SET_DATUM_ADDRESS = 'SET_MEMBER_ADDRESS'

export const GET_COUNT_FETCHING = 'GET_MEMBER_COUNT_FETCHING'
export const GET_COUNT_SUCCESS = 'GET_MEMBER_COUNT_SUCCESS'
export const GET_COUNT_FAILED = 'GET_MEMBER_COUNT_FAILED'

export const GET_DATA_FETCHING = 'GET_MEMBERS_FETCHING'
export const GET_ALL_DATA_SUCCESS = 'GET_ALL_MEMBERS_SUCCESS'
export const GET_PAGED_DATA_SUCCESS = 'GET_PAGED_MEMBERS_SUCCESS'
export const GET_DATA_FAILED = 'GET_MEMBERS_FAILED'

export const GET_SELECT_LIST_DATA_FETCHING = 'GET_SELECT_LIST_MEMBERS_FETCHING'
export const GET_SELECT_LIST_DATA_SUCCESS = 'GET_SELECT_LIST_MEMBERS_SUCCESS'
export const GET_SELECT_LIST_DATA_FAILED = 'GET_SELECT_LIST_MEMBERS_FAILED'

export const GET_SELECT_TITLE_DATA_FETCHING = 'GET_SELECT_TITLE_MEMBERS_FETCHING'
export const GET_SELECT_TITLE_DATA_SUCCESS = 'GET_SELECT_TITLE_MEMBERS_SUCCESS'
export const GET_SELECT_TITLE_DATA_FAILED = 'GET_SELECT_TITLE_MEMBERS_FAILED'

export const GET_SELECT_GENDER_DATA_FETCHING = 'GET_SELECT_GENDER_MEMBERS_FETCHING'
export const GET_SELECT_GENDER_DATA_SUCCESS = 'GET_SELECT_GENDER_MEMBERS_SUCCESS'
export const GET_SELECT_GENDER_DATA_FAILED = 'GET_SELECT_GENDER_MEMBERS_FAILED'

export const GET_SELECT_MARITAL_STATUS_DATA_FETCHING = 'GET_SELECT_MARITAL_STATUS_MEMBERS_FETCHING'
export const GET_SELECT_MARITAL_STATUS_DATA_SUCCESS = 'GET_SELECT_MARITAL_STATUS_MEMBERS_SUCCESS'
export const GET_SELECT_MARITAL_STATUS_DATA_FAILED = 'GET_SELECT_MARITAL_STATUS_MEMBERS_FAILED'

export const GET_SELECT_MEMBERSHIP_STATUS_DATA_FETCHING = 'GET_SELECT_MEMBERSHIP_STATUS_MEMBERS_FETCHING'
export const GET_SELECT_MEMBERSHIP_STATUS_DATA_SUCCESS = 'GET_SELECT_MEMBERSHIP_STATUS_MEMBERS_SUCCESS'
export const GET_SELECT_MEMBERSHIP_STATUS_DATA_FAILED = 'GET_SELECT_MEMBERSHIP_STATUS_MEMBERS_FAILED'

export const GET_SELECT_EDUCATION_LEVEL_DATA_FETCHING = 'GET_SELECT_EDUCATION_LEVEL_MEMBERS_FETCHING'
export const GET_SELECT_EDUCATION_LEVEL_DATA_SUCCESS = 'GET_SELECT_EDUCATION_LEVEL_MEMBERS_SUCCESS'
export const GET_SELECT_EDUCATION_LEVEL_DATA_FAILED = 'GET_SELECT_EDUCATION_LEVEL_MEMBERS_FAILED'

export const GET_SELECT_MEMBERSHIP_JUSTIFICATION_DATA_FETCHING = 'GET_SELECT_MEMBERSHIP_JUSTIFICATION_MEMBERS_FETCHING'
export const GET_SELECT_MEMBERSHIP_JUSTIFICATION_DATA_SUCCESS = 'GET_SELECT_MEMBERSHIP_JUSTIFICATION_MEMBERS_SUCCESS'
export const GET_SELECT_MEMBERSHIP_JUSTIFICATION_DATA_FAILED = 'GET_SELECT_MEMBERSHIP_JUSTIFICATION_MEMBERS_FAILED'

export const GET_SELECT_COUNTRY_CODES_DATA_FETCHING = 'GET_SELECT_COUNTRY_CODES_MEMBERS_FETCHING'
export const GET_SELECT_COUNTRY_CODES_DATA_SUCCESS = 'GET_SELECT_COUNTRY_CODES_MEMBERS_SUCCESS'
export const GET_SELECT_COUNTRY_CODES_DATA_FAILED = 'GET_SELECT_COUNTRY_CODES_MEMBERS_FAILED'

export const GET_DATUM_FETCHING = 'GET_MEMBER_FETCHING'
export const GET_DATUM_SUCCESS = 'GET_MEMBER_SUCCESS'
export const GET_DATUM_FAILED = 'GET_MEMBER_FAILED'

export const GET_MEMBER_BUSINESS_FETCHING = 'GET_MEMBER_BUSINESS_FETCHING'
export const GET_MEMBER_BUSINESS_SUCCESS = 'GET_MEMBER_BUSINESS_SUCCESS'
export const GET_MEMBER_BUSINESS_FAILED = 'GET_MEMBER_BUSINESS_FAILED'

export const ADD_DATUM_FETCHING = 'ADD_MEMBER_FETCHING'
export const ADD_DATUM_SUCCESS = 'ADD_MEMBER_SUCCESS'
export const ADD_DATUM_FAILED = 'ADD_MEMBER_FAILED'

export const UPDATE_DATUM_FETCHING = 'UPDATE_MEMBER_FETCHING'
export const UPDATE_DATUM_SUCCESS = 'UPDATE_MEMBER_SUCCESS'
export const UPDATE_DATUM_FAILED = 'UPDATE_MEMBER_FAILED'

export const DELETE_DATUM_FETCHING = 'DELETE_MEMBER_FETCHING'
export const DELETE_DATUM_SUCCESS = 'DELETE_MEMBER_SUCCESS'
export const DELETE_DATUM_FAILED = 'DELETE_MEMBER_FAILED'

export const DELETE_DATA_FETCHING = 'DELETE_MEMBERS_FETCHING'
export const DELETE_DATA_SUCCESS = 'DELETE_MEMBERS_SUCCESS'
export const DELETE_DATA_FAILED = 'DELETE_MEMBERS_FAILED'

export const UPDATE_MEMBER_ADDRESS_FETCHING = 'UPDATE_MEMBER_ADDRESS_FETCHING'
export const UPDATE_MEMBER_ADDRESS_SUCCESS = 'UPDATE_MEMBER_ADDRESS_SUCCESS'
export const UPDATE_MEMBER_ADDRESS_FAILED = 'UPDATE_MEMBER_ADDRESS_FAILED'
