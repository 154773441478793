
export const SET_DATUM_DETAILS = 'SET_NATURAL_GROUP_DETAILS'

export const GET_COUNT_FETCHING = 'GET_NATURAL_GROUP_COUNT_FETCHING'
export const GET_COUNT_SUCCESS = 'GET_NATURAL_GROUP_COUNT_SUCCESS'
export const GET_COUNT_FAILED = 'GET_NATURAL_GROUP_COUNT_FAILED'

export const GET_DATA_FETCHING = 'GET_NATURAL_GROUPS_FETCHING'
export const GET_ALL_DATA_SUCCESS = 'GET_ALL_NATURAL_GROUPS_SUCCESS'
export const GET_PAGED_DATA_SUCCESS = 'GET_PAGED_NATURAL_GROUPS_SUCCESS'
export const GET_DATA_FAILED = 'GET_NATURAL_GROUPS_FAILED'

export const GET_SELECT_LIST_DATA_FETCHING = 'GET_SELECT_LIST_NATURAL_GROUPS_FETCHING'
export const GET_SELECT_LIST_DATA_SUCCESS = 'GET_SELECT_LIST_NATURAL_GROUPS_SUCCESS'
export const GET_SELECT_LIST_DATA_FAILED = 'GET_SELECT_LIST_NATURAL_GROUPS_FAILED'

export const GET_SELECT_RULESET_DATA_FETCHING = 'GET_SELECT_RULESET_FETCHING'
export const GET_SELECT_RULESET_DATA_SUCCESS = 'GET_SELECT_RULESET_SUCCESS'
export const GET_SELECT_RULESET_DATA_FAILED = 'GET_SELECT_RULESET_FAILED'

export const GET_SELECT_MEMBERS_DATA_FETCHING = 'GET_SELECT_N_MEMBERS_FETCHING'
export const GET_SELECT_MEMBERS_DATA_SUCCESS = 'GET_SELECT_N_MEMBERS_SUCCESS'
export const GET_SELECT_MEMBERS_DATA_FAILED = 'GET_SELECT_N_MEMBERS_FAILED'

export const GET_DATUM_FETCHING = 'GET_NATURAL_GROUP_FETCHING'
export const GET_DATUM_SUCCESS = 'GET_NATURAL_GROUP_SUCCESS'
export const GET_DATUM_FAILED = 'GET_NATURAL_GROUP_FAILED'

export const ADD_DATUM_FETCHING = 'ADD_NATURAL_GROUP_FETCHING'
export const ADD_DATUM_SUCCESS = 'ADD_NATURAL_GROUP_SUCCESS'
export const ADD_DATUM_FAILED = 'ADD_NATURAL_GROUP_FAILED'

export const UPDATE_DATUM_FETCHING = 'UPDATE_NATURAL_GROUP_FETCHING'
export const UPDATE_DATUM_SUCCESS = 'UPDATE_NATURAL_GROUP_SUCCESS'
export const UPDATE_DATUM_FAILED = 'UPDATE_NATURAL_GROUP_FAILED'

export const DELETE_DATUM_FETCHING = 'DELETE_NATURAL_GROUP_FETCHING'
export const DELETE_DATUM_SUCCESS = 'DELETE_NATURAL_GROUP_SUCCESS'
export const DELETE_DATUM_FAILED = 'DELETE_NATURAL_GROUP_FAILED'

export const DELETE_DATA_FETCHING = 'DELETE_NATURAL_GROUPS_FETCHING'
export const DELETE_DATA_SUCCESS = 'DELETE_NATURAL_GROUPS_SUCCESS'
export const DELETE_DATA_FAILED = 'DELETE_NATURAL_GROUPS_FAILED'
