
export const SET_DATUM_DETAILS = 'SET_LGA_DETAILS'

export const GET_DATA_FETCHING = 'GET_LGAS_FETCHING'
export const GET_ALL_DATA_SUCCESS = 'GET_ALL_LGAS_SUCCESS'
export const GET_PAGED_DATA_SUCCESS = 'GET_PAGED_LGAS_SUCCESS'
export const GET_DATA_FAILED = 'GET_LGAS_FAILED'

export const GET_SELECT_LIST_DATA_FETCHING = 'GET_SELECT_LIST_LGAS_FETCHING'
export const GET_SELECT_LIST_DATA_SUCCESS = 'GET_SELECT_LIST_LGAS_SUCCESS'
export const GET_SELECT_LIST_DATA_FAILED = 'GET_SELECT_LIST_LGAS_FAILED'

export const GET_DATUM_FETCHING = 'GET_LGA_FETCHING'
export const GET_DATUM_SUCCESS = 'GET_LGA_SUCCESS'
export const GET_DATUM_FAILED = 'GET_LGA_FAILED'

export const ADD_DATUM_FETCHING = 'ADD_LGA_FETCHING'
export const ADD_DATUM_SUCCESS = 'ADD_LGA_SUCCESS'
export const ADD_DATUM_FAILED = 'ADD_LGA_FAILED'

export const UPDATE_DATUM_FETCHING = 'UPDATE_LGA_FETCHING'
export const UPDATE_DATUM_SUCCESS = 'UPDATE_LGA_SUCCESS'
export const UPDATE_DATUM_FAILED = 'UPDATE_LGA_FAILED'

export const DELETE_DATUM_FETCHING = 'DELETE_LGA_FETCHING'
export const DELETE_DATUM_SUCCESS = 'DELETE_LGA_SUCCESS'
export const DELETE_DATUM_FAILED = 'DELETE_LGA_FAILED'

export const DELETE_DATA_FETCHING = 'DELETE_LGAS_FETCHING'
export const DELETE_DATA_SUCCESS = 'DELETE_LGAS_SUCCESS'
export const DELETE_DATA_FAILED = 'DELETE_LGAS_FAILED'
