import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../../@jumbo/components/Common/formElements/AppTextInput';
import CmtAvatar from '../../../../../@coremat/CmtAvatar';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import CmtList from '../../../../../@coremat/CmtList';   // '../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import AppSelectBox from '../../../../../@jumbo/components/Common/formElements/AppSelectBox';
import AppDatePicker from '../../../../../@jumbo/components/Common/formElements/AppDatePicker';
import { emailNotValid, requiredMessage } from '../../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import { isValidEmail } from '../../../../../@jumbo/utils/commonHelper';
import { 
  getData, 
  addData, 
  updateData, 
  titleSelectData, 
  genderSelectData, 
  maritalStatusSelectData,
  membershipStatusSelectData,
  educationLevelSelectData,
  countryCodeSelectData
} from '../store/action';    // '../../../../redux/actions/Users';
//import { getSelectData as countrySelectData } from '../../country/store/action';


const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
}));


function PhoneNumberInput({ onChange, value, ...other }) {
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    if (!phoneNumber && value) {
      setTimeout(() => {
        setPhoneNumber(value);
      }, 300);
    }
  }, [phoneNumber, value]);

  const onNumberChange = number => {
    setPhoneNumber(number.formattedValue);
    onChange(number.formattedValue);
  };

  return <NumberFormat {...other} onValueChange={onNumberChange} value={phoneNumber} format="#### ### ####" />;
}

const AddEditDialog = ({ open, onCloseDialog, onAddSuccess, onAddFailed, onUpdateSuccess, onUpdateFailed }) => {
  const classes = useStyles();

  const { selectedItem, titles, genders, maritalStatuses, statuses, countryCodes, eduLevels, finish } = useSelector(({ firstTimers }) => firstTimers);
  //const countryStore = useSelector(state => state.countries);

  
  const [profile_pic, setProfile_pic] = useState(selectedItem?.profile_pic);
  const [picFile, setPicFile] = useState();

  const [title, setTitle] = useState(selectedItem?.title ?? '');
  const [titleError, setTitleError] = useState('');
  
  const [lastName, setLastName] = useState(selectedItem?.lastname);
  const [lastNameError, setLastNameError] = useState('');
   
  const [firstName, setFirstName] = useState(selectedItem?.firstname);
  const [firstNameError, setFirstNameError] = useState('');
   
  const [otherNames, setOtherNames] = useState(selectedItem?.othernames);
  const [otherNamesError, setOtherNamesError] = useState('');

  const [dob, setDob] = useState(selectedItem?.dob)
  const [dobError, setDobError] = useState('');

  const [gender, setGender] = useState(selectedItem?.gender ?? '');
  const [genderError, setGenderError] = useState('');
  
  const [maritalStatus, setMaritalStatus] = useState(selectedItem?.maritalstatus ?? '');
  const [maritalStatusError, setMaritalStatusError] = useState('');
    
  const [email, setEmail] = useState(selectedItem?.email);
  const [emailError, setEmailError] = useState('');

  const [phone, setPhone] = useState(selectedItem?.phone);
  const [phoneError, setPhoneError] = useState('');

  const [countryCode, setCountryCode] = useState(selectedItem?.countrycode ?? '');
  const [countryCodeError, setCountryCodeError] = useState('');



  const max_date = moment().add(-10, 'year').toDate();
  const min_date = moment().add(-120, 'year').toDate();

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      getBase64(acceptedFiles[0]);
      setProfile_pic(URL.createObjectURL(acceptedFiles[0]));
    },
  });
  
  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setPicFile(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const [currentMember, setCurrentMember] = useState(selectedItem);

  const dispatch = useDispatch();

  useEffect(() => {

    setCurrentMember(selectedItem);

    dispatch(titleSelectData(() => {
      // do nothing
    }, () => {
      // do nothing
    }))

    dispatch(genderSelectData(() => {
      // do nothing
    }, () => {
      // do nothing
    }))

    dispatch(maritalStatusSelectData(() => {
      // do nothing
    }, () => {
      // do nothing
    }))
    
    dispatch(countryCodeSelectData(() => {
      // do nothing
    }, () => {
      // do nothing
    }))
  }, []);

  const onSubmitClick = () => {
    if (!title) {
      setTitleError(requiredMessage);
    }
    if (!lastName) {
      setLastNameError(requiredMessage);
    }
    if (!firstName) {
      setFirstNameError(requiredMessage);
    }
    // if (!email){
    //   setEmailError(requiredMessage);
    // }
    if (!!email && !isValidEmail(email)) {
      setEmailError(emailNotValid);
    }
    if (!dob) {
      setDobError(requiredMessage);
    }
    if (!gender) {
      setGenderError(requiredMessage);
    }
    if (!maritalStatus) {
      setMaritalStatusError(requiredMessage);
    }
    if (!phone) {
      setPhoneError(requiredMessage);
    }
    if (!countryCode) {
      setCountryCodeError(requiredMessage);
    }

    //email && isValidEmail(email) &&
    if (title && lastName && firstName && 
      dob && gender && maritalStatus &&
      phone && countryCode) {
      onMemberSave();
    }
  };

  const onMemberSave = () => {
    const newMember = {
      title: title,
      lastName: lastName,
      firstName: firstName,
      otherNames: otherNames,
      dob: dob,
      email: email,
      phone: phone,
      countryCode: countryCode,
      gender: gender,
      maritalStatus: maritalStatus,
      profile_pic: picFile
    };

    if (currentMember) {
      dispatch(
        updateData({ ...currentMember, ...newMember }, () => {
          if (onUpdateSuccess)
            onUpdateSuccess();
          onCloseDialog();
        }, (err) => {
          if (onUpdateFailed)
            onUpdateFailed(err);
        }),
      );
    } else {
      dispatch(
        addData(newMember, () => {
          if (onAddSuccess)
            onAddSuccess();
          onCloseDialog();
        }, (err) => {
          if (onAddFailed)
            onAddFailed(err);
        }),
      );
    }
  };

  
  const onPhoneChange = (value) => {

    setPhone(value);
    setPhoneError('');

  };
  //console.log(picFile)

  return (
      <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot} fullWidth={true}>
        <DialogTitle className={classes.dialogTitleRoot}>{currentMember ? 'Edit First Timer' : 'Create New First Timer'}</DialogTitle>
        <DialogContent dividers>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
          <Box {...getRootProps()} mr={{ xs: 0, md: 5 }} mb={{ xs: 3, md: 0 }} className="pointer">
            <input {...getInputProps()} />
            <CmtAvatar size={70} src={profile_pic} />
          </Box>
        </Box>

          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={3} sm={3}>
                <AppSelectBox
                  fullWidth
                  variant="outlined"
                  label="Title"
                  value={title}
                  data={titles}
                  valueKey="value"
                  labelKey="label"
                  onChange={e => {
                    setTitle(e.target.value);
                    setTitleError('');
                  }}
                  helperText={titleError}
                />
              </Grid>
              <Grid item xs={9} sm={9}>
                <AppTextInput
                  fullWidth
                  variant="outlined"
                  label="Surname"
                  value={lastName}
                  onChange={e => {
                    setLastName(e.target.value);
                    setLastNameError('');
                  }}
                  helperText={lastNameError}
                />
              </Grid>
            </GridContainer>
          </Box>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={6}>
                <AppTextInput
                  fullWidth
                  variant="outlined"
                  label="First Name"
                  value={firstName}
                  onChange={e => {
                    setFirstName(e.target.value);
                    setFirstNameError('');
                  }}
                  helperText={firstNameError}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <AppTextInput
                  fullWidth
                  variant="outlined"
                  label="Other Names"
                  value={otherNames}
                  onChange={e => {
                    setOtherNames(e.target.value);
                    setOtherNamesError('');
                  }}
                  helperText={otherNamesError}
                />
              </Grid>
            </GridContainer>
          </Box>

          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={6}>
                <AppSelectBox
                  fullWidth
                  variant="outlined"
                  label="Gender"
                  value={gender}
                  data={genders}
                  valueKey="value"
                  labelKey="label"
                  onChange={e => {
                    setGender(e.target.value);
                    setGenderError('');
                  }}
                  helperText={genderError}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <AppSelectBox
                  fullWidth
                  variant="outlined"
                  label="Marital Status"
                  value={maritalStatus}
                  data={maritalStatuses}
                  valueKey="value"
                  labelKey="label"
                  onChange={e => {
                    setMaritalStatus(e.target.value);
                    setMaritalStatusError('');
                  }}
                  helperText={maritalStatusError}
                />
              </Grid>
            </GridContainer>
          </Box>
          
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AppDatePicker
                    fullWidth
                    disableFuture={true}
                    inputVariant="outlined"
                    format='DD-MM-YYYY'
                    minDate={min_date}
                    maxDate={max_date}
                    label="Date of Birth"
                    value={dob}
                    onChange={date => {
                      setDob(date.format("YYYY-MM-DD"));
                      setDobError('');
                    }}
                    helperText={dobError}
                />
              </Grid>
            </GridContainer>
          </Box>

          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AppTextInput
                    fullWidth
                    variant="outlined"
                    label="Email"
                    value={email}
                    onChange={e => {
                      setEmail(e.target.value);
                      setEmailError('');
                    }}
                    helperText={emailError}
                />
              </Grid>
            </GridContainer>
          </Box>
          
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
            <Grid item xs={12} sm={4}>
                <AppSelectBox
                  fullWidth
                  variant="outlined"
                  label="Country Code"
                  value={countryCode}
                  data={countryCodes}
                  valueKey="value"
                  labelKey="label"
                  onChange={e => {
                    setCountryCode(e.target.value);
                    setCountryCodeError('');
                  }}
                  helperText={countryCodeError}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <AppTextInput
                  fullWidth
                  variant="outlined"
                  label="Phone"                 
                  value={phone}
                  onChange={e => {
                    setPhone(e.target.value)
                    setPhoneError('')
                  }}
                  helperText={phoneError}
                  InputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  // InputProps={{
                  //   inputComponent: PhoneNumberInput,
                  //   inputProps: { value: phone, onChange: onPhoneChange },
                  // }}
                />
              </Grid>
            </GridContainer>
          </Box>

          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button onClick={onCloseDialog}>Cancel</Button>
            <Box ml={2}>
            <Button disabled={!finish} variant="contained" color="primary" onClick={onSubmitClick}>
                Save
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
  );
};

export default AddEditDialog;

AddEditDialog.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
