import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
//import Dashboards from './Dashboards';
//import Components from './Components';
//import Apps from './Apps';
//import Extensions from './Extensions';
//import Charts from './Charts';
//import Maps from './Maps';
//import Widgets from './Widgets';
//import Metrics from './Metrics';
import Login from './Auth/Login';
import Signup from './Auth/Register';
import ForgotPassword from './Auth/ForgotPassword';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ExtraPages from './ExtraPages';
import TourGuide from './TourGuide';
//import CustomTimelines from './views/CustomTimelines';
//import MaterialTimelines from './views/MaterialTimelines';
//import Calendar from './modules/Calendar';
//import UsersModule from './modules/Users';
// import LayoutBuilder from './LayoutBuilder';
import DashboardModule from './app/dashboards';
import CountriesModule from './app/settings/country';
import CurrenciesModule from './app/settings/currency';
import StatesModule from './app/settings/state';
import RegionsModule from './app/settings/region';
import LGAsModule from './app/settings/lga';
import LocationsModule from './app/settings/location';
import Profile from './app/my/profile';
import AllMembersModule from './app/members/members';
import FirstTimersModule from './app/members/firstTimers';
import CongregantMembersModule from './app/members/members/congregant';
import DependentMembersModule from './app/members/members/dependent';
import BonafideMembersModule from './app/members/members/bonafide';
import LeaderMembersModule from './app/members/members/leader';
import StewardMembersModule from './app/members/members/steward';
import MinisterMembersModule from './app/members/members/minister';
import DeaconMembersModule from './app/members/members/deacon';
import AssociateMembersModule from './app/members/members/associate';
import PastorMembersModule from './app/members/members/pastor';

import AccountsModule from './app/account';
import AdminsModule from './app/admin';
import ActivityTemplatesModule from './app/management/activitytemplate';
import FtManagementGroupModule from './app/management/ftmgmtgroup';
import NaturalGroupsModule from './app/management/naturalgroup';
import ProgramsModule from './app/management/program';
import DepartmentsModule from './app/management/department';
import DistrictsModule from './app/management/district';
import ZonesModule from './app/management/zone';
import CellsModule from './app/management/cell';
import LoveCentersModule from './app/management/lovecenter';

import JobCategoriesModule from './app/advancement/jobCategory';
import JobCompaniesModule from './app/advancement/jobCompany';
import JobTagsModule from './app/advancement/jobTag';
import JobsModule from './app/advancement/job';

import BusinessesModule from './app/advancement/business';

import PageLoader from '../@jumbo/components/PageComponents/PageLoader';



const RestrictedRoute = ({ component: Component, roles, ...rest }) => {
  const { authUser, roles: currentRoles } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        authUser  ? (//&& roles?.some(r => currentRoles.includes(r))
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();

  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/dashboard'} />;
  } else if (authUser && location.pathname === '/signin') {
    return <Redirect to={'/dashboard'} />;
  }

  return (
    <React.Fragment>
      <Suspense fallback={<PageLoader />}>
        <Switch>
          <RestrictedRoute path="/dashboard" component={DashboardModule} />
          {/* <RestrictedRoute path="/widgets" component={Widgets} />
          <RestrictedRoute path="/metrics" component={Metrics} />
          <RestrictedRoute path="/components" component={Components} />
          <RestrictedRoute path="/extensions" component={Extensions} />
          <RestrictedRoute path="/visualization/chart" component={Charts} />
          <RestrictedRoute path="/visualization/map" component={Maps} />
          <RestrictedRoute path="/extra-pages" component={ExtraPages} />
          <RestrictedRoute path="/apps" component={Apps} />
          <RestrictedRoute path="/custom-timeline" component={CustomTimelines} />
          <RestrictedRoute path="/material-timeline" component={MaterialTimelines} />
          <RestrictedRoute path="/calendar" component={Calendar} />
          <RestrictedRoute path="/users" component={UsersModule} /> */}
          <RestrictedRoute path='/my/profile' component={Profile} />
          <RestrictedRoute path='/settings/countries' component={CountriesModule} />
          <RestrictedRoute path='/settings/currencies' component={CurrenciesModule} />
          <RestrictedRoute path='/settings/states' component={StatesModule} />
          <RestrictedRoute path='/settings/regions' component={RegionsModule} />
          <RestrictedRoute path='/settings/lgas' component={LGAsModule} />
          <RestrictedRoute path='/settings/locations' component={LocationsModule} />
          <RestrictedRoute path='/members/firsttimers' component={FirstTimersModule} />
          <RestrictedRoute path='/members/all' component={AllMembersModule} />
          <RestrictedRoute path='/members/congregants' component={CongregantMembersModule} />
          <RestrictedRoute path='/members/dependents' component={DependentMembersModule} />
          <RestrictedRoute path='/members/bonafides' component={BonafideMembersModule} />        
          <RestrictedRoute path='/members/leaders' component={LeaderMembersModule} />
          <RestrictedRoute path='/members/stewards' component={StewardMembersModule} />
          <RestrictedRoute path='/members/ministers' component={MinisterMembersModule} />
          <RestrictedRoute path='/members/deacons' component={DeaconMembersModule} />
          <RestrictedRoute path='/members/pastors/assistants' component={AssociateMembersModule} />
          <RestrictedRoute path='/members/pastors' component={PastorMembersModule} />

          <RestrictedRoute path='/management/accounts' component={AccountsModule} />
          <RestrictedRoute path='/management/activitytemplates' component={ActivityTemplatesModule} />
          <RestrictedRoute path='/management/ftmgmtvrp' component={FtManagementGroupModule} />
          <RestrictedRoute path='/management/programs' component={ProgramsModule} />
          <RestrictedRoute path='/management/naturalgroups' component={NaturalGroupsModule} />
          <RestrictedRoute path='/management/departments' component={DepartmentsModule} />
          <RestrictedRoute path='/management/districts' component={DistrictsModule} />
          <RestrictedRoute path='/management/zones' component={ZonesModule} />
          <RestrictedRoute path='/management/cells' component={CellsModule} />
          <RestrictedRoute path='/management/lovecenters' component={LoveCentersModule} />

          <RestrictedRoute path='/users/admins/list' component={AdminsModule} />

          <RestrictedRoute path='/advancement/jobcategories' component={JobCategoriesModule} />
          <RestrictedRoute path='/advancement/jobcompanies' component={JobCompaniesModule} />
          <RestrictedRoute path='/advancement/jobtags' component={JobTagsModule} />
          <RestrictedRoute path='/advancement/jobs' component={JobsModule} />
          <RestrictedRoute path='/advancement/business/listings' component={BusinessesModule} />

          <Route path="/signin" component={Login} />
          {/* <Route path="/signup" component={Signup} /> */}
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="forbidden" component={'./ExtraPages/403'} />
          <Route path="notfound" component={'./ExtraPages/404'} />
          <Route path="server-error" component={'./ExtraPages/500'} />
          {/*<Route path="/layout-builder" component={LayoutBuilder} />*/}
          <Route component={lazy(() => import('./ExtraPages/404'))} />
        </Switch>
      </Suspense>
      {location.pathname !== '/signin' && location.pathname !== '/signup' && location.pathname !== '/forgot-password' && (
        <TourGuide />
      )}
    </React.Fragment>
  );
};

export default Routes;
