import React, { useState } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import PropTypes from 'prop-types';
import { Button, Chip, Menu, MenuItem } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { deleteBulkData } from '../store/action';// '../../../../redux/actions/Users';
import ConfirmDialog from '../../../../../@jumbo/components/Common/ConfirmDialog';
import CmtSearch from '../../../../../@coremat/CmtSearch';
// import Notifications, { createNotification } from '../../../../../components/extensions/Notifications';
import useStyles from './index.style';
import Checkbox from '@material-ui/core/Checkbox';

const filterOptionsList = [
  { label: 'Active', value: 'active' },
  { label: 'Suspended', value: 'suspended' },
];

const TableToolbar = ({
  selected,
  setSelected,
  onAdd,
  filterOptions,
  setFilterOptions,
  searchTerm,
  setSearchTerm,
  onBulkDeleteSuccess,
  onBulkDeleteFailed,
  loading
}) => {
  const classes = useStyles();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const dispatch = useDispatch();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDeleteCLick = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDialog(false);
    dispatch(deleteBulkData(selected, () => {
      setSelected([]);
      if (onBulkDeleteSuccess)
        onBulkDeleteSuccess();
    }, (err) => {
      if (onBulkDeleteFailed)
        onBulkDeleteFailed(err);
    }));
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
  };

  const onFilterOptionClick = option => {
    setFilterOptions(prevState => {
      if (prevState.includes(option.value)) {
        return prevState.filter(item => item !== option.value);
      } else {
        return [...prevState, option.value];
      }
    });
  };

  const onChipDelete = option => {
    setFilterOptions(filterOptions.filter(item => item !== option.value));
  };

  const onSearchChipDelete = () => setSearchTerm('');

  const numSelected = selected.length;

  return (
    <React.Fragment>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}>
        {numSelected > 0 ? (
          <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
            Jobs{' '}
            <Button color="primary" onClick={() => onAdd(true)}>
              Add New Job
            </Button>
          </Typography>
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="delete" onClick={onDeleteCLick}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <React.Fragment>
            <CmtSearch onChange={e => setSearchTerm(e.target.value)} value={searchTerm} border={false} onlyIcon />
          </React.Fragment>
        )}
      </Toolbar>

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Confirm delete jobs`}
        content={'Are you sure, you want to delete selected jobs?'}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        loading={loading}
      />

    </React.Fragment>
  );
};

TableToolbar.propTypes = {
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  filterOptions: PropTypes.array,
  setFilterOptions: PropTypes.func,
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
  onUserAdd: PropTypes.func,
};

export default React.memo(TableToolbar);
