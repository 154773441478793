import { errorMonitor } from 'events';
import axios from '../../../../../../services/auth/jwt/config';   // 'axios'
import {
  SET_DATUM_DETAILS,
  GET_COUNT_FETCHING,
  GET_COUNT_SUCCESS,
  GET_COUNT_FAILED,
  GET_DATA_FETCHING,
  GET_ALL_DATA_SUCCESS,
  GET_PAGED_DATA_SUCCESS,
  GET_DATA_FAILED,
  GET_SELECT_LIST_DATA_FETCHING,
  GET_SELECT_LIST_DATA_SUCCESS,
  GET_SELECT_LIST_DATA_FAILED,
  GET_SELECT_TEAM_LEADS_DATA_FETCHING,
  GET_SELECT_TEAM_LEADS_DATA_SUCCESS,
  GET_SELECT_TEAM_LEADS_DATA_FAILED,
  GET_SELECT_MEMBERS_DATA_FETCHING,
  GET_SELECT_MEMBERS_DATA_SUCCESS,
  GET_SELECT_MEMBERS_DATA_FAILED,
  GET_DATUM_FETCHING,
  GET_DATUM_SUCCESS,
  GET_DATUM_FAILED,
  ADD_DATUM_FETCHING,
  ADD_DATUM_SUCCESS,
  ADD_DATUM_FAILED,
  UPDATE_DATUM_FETCHING,
  UPDATE_DATUM_SUCCESS,
  UPDATE_DATUM_FAILED,
  DELETE_DATUM_FETCHING,
  DELETE_DATUM_SUCCESS,
  DELETE_DATUM_FAILED,
  DELETE_DATA_FETCHING,
  DELETE_DATA_SUCCESS,
  DELETE_DATA_FAILED,
  REMOVE_TEAM_LEAD_FETCHING,
  REMOVE_TEAM_LEAD_SUCCESS,
  REMOVE_TEAM_LEAD_FAILED,
  REMOVE_MEMBER_FETCHING,
  REMOVE_MEMBER_SUCCESS,
  REMOVE_MEMBER_FAILED,
} from '../type'


export const setCurrentItem = item => {
  return dispatch => {
    dispatch({ type: SET_DATUM_DETAILS, payload: item });
  };
};

// ** Count all Data
export const countData = (callbackFunc, failedFunc) => {
  return async dispatch => {
    dispatch({
      type: GET_COUNT_FETCHING
    })

    try {
      const response = await axios.get('/v1/firsttimermanagementgroup/count')    //.then(response => {
      
      dispatch({
        type: GET_COUNT_SUCCESS,
        payload: { data: response.data }
       })
       if (callbackFunc) 
        callbackFunc();
    } catch (err) {
      dispatch({
        type: GET_COUNT_FAILED,
        payload: { data: null, error: err }
       })
      if (failedFunc)
        failedFunc(err);
    }
  }
}

// ** Get all Data
export const getAllData = (callbackFunc, failedFunc) => {
  return async dispatch => {
    dispatch({
      type: GET_DATA_FETCHING
    })

    try {
      const response = await axios.get('/v1/firsttimermanagementgroup')    //.then(response => {
      
      dispatch({
        type: GET_ALL_DATA_SUCCESS,
        payload: { data: response.data }
       })
       if (callbackFunc) 
        callbackFunc();
    } catch (err) {
      dispatch({
        type: GET_DATA_FAILED,
        payload: { data: null, error: err }
       })
      if (failedFunc)
        failedFunc(err);
    }
  }
}

// ** Get data on page or row change
export const getPagedData = (params, callbackFunc, failedFunc) => {
  return async dispatch => {
    dispatch({
      type: GET_DATA_FETCHING
    })

    try {
      const response = await axios.post('/v1/firsttimermanagementgroup/search', params)    //.then(response => {
      dispatch({
        type: GET_PAGED_DATA_SUCCESS,
        payload: {
          data: response.data.items,
          totalPages: response.data.total,
          params
        }
      })
      if (callbackFunc) 
        callbackFunc();
    } catch (err) {
      dispatch({
        type: GET_DATA_FAILED,
        payload: { data: null, error: err }
      })
      if (failedFunc)
        failedFunc(err);
    }
  }
}

// ** Get data on page or row change
export const teamLeadSelectData = (callbackFunc, failedFunc) => {
  return async dispatch => {
    dispatch({
      type: GET_SELECT_TEAM_LEADS_DATA_FETCHING
    })

    try {
      const response = await axios.get(`/v1/firsttimermanagementgroup/select/teamleads`)    //.then(response => {
      dispatch({
        type: GET_SELECT_TEAM_LEADS_DATA_SUCCESS,
        payload: {
          data: response.data
        }
      })
      if (callbackFunc) 
        callbackFunc();

      return Promise.resolve(response.data)
    } catch (err) {
      dispatch({
        type: GET_SELECT_TEAM_LEADS_DATA_FAILED,
        payload: { data: null, error: err }
      })
      if (failedFunc)
        failedFunc(err);

      return Promise.reject(err)
    }
  }
}

// ** Get data on page or row change
export const groupMemberSelectData = (id, callbackFunc, failedFunc) => {
  return async dispatch => {
    dispatch({
      type: GET_SELECT_MEMBERS_DATA_FETCHING
    })

    try {
      const response = await axios.get(`/v1/firsttimermanagementgroup/select/seniorconvenantpartners/${id}`)    //.then(response => {
      dispatch({
        type: GET_SELECT_MEMBERS_DATA_SUCCESS,
        payload: {
          data: response.data
        }
      })
      if (callbackFunc) 
        callbackFunc();

      return Promise.resolve(response.data)
    } catch (err) {
      dispatch({
        type: GET_SELECT_MEMBERS_DATA_FAILED,
        payload: { data: null, error: err }
      })
      if (failedFunc)
        failedFunc(err);

      return Promise.reject(err)
    }
  }
}

// ** Get data on page or row change
export const getSelectData = (callbackFunc, failedFunc) => {
  return async dispatch => {
    dispatch({
      type: GET_SELECT_LIST_DATA_FETCHING
    })

    try {
      const response = await axios.get('/v1/firsttimermanagementgroup/select')    //.then(response => {
      dispatch({
        type: GET_SELECT_LIST_DATA_SUCCESS,
        payload: {
          data: response.data
        }
      })
      if (callbackFunc) 
        callbackFunc();

      return Promise.resolve(response.data)
    } catch (err) {
      dispatch({
        type: GET_SELECT_LIST_DATA_FAILED,
        payload: { data: null, error: err }
      })
      if (failedFunc)
        failedFunc(err);

      return Promise.reject(err)
    }
  }
}

// ** Get data on page or row change
export const getSearchableSelectData = (search, callbackFunc, failedFunc) => {
  return async dispatch => {
    dispatch({
      type: GET_SELECT_LIST_DATA_FETCHING
    })

    try {
      const response = await axios.post('/v1/firsttimermanagementgroup/searchSelect', { search })    //.then(response => {
      dispatch({
        type: GET_SELECT_LIST_DATA_SUCCESS,
        payload: {
          data: response.data
        }
      })
      if (callbackFunc) 
        callbackFunc();

      return Promise.resolve(response.data)
    } catch (err) {
      dispatch({
        type: GET_SELECT_LIST_DATA_FAILED,
        payload: { data: null, error: err }
      })
      if (failedFunc)
        failedFunc(err);

      return Promise.reject(err)
    }
  }
}

// ** Get Data
export const getData = (id, callbackFunc, failedFunc) => {
  return async dispatch => {
    dispatch({
      type: GET_DATUM_FETCHING
    })

    try {
      const response = await axios.get(`/v1/firsttimermanagementgroup/${id}`)      //.then(response => {
      dispatch({
        type: GET_DATUM_SUCCESS,
        payload: { selectedItem: response.data }
      })
      if (callbackFunc) 
        callbackFunc();

    } catch (err) {
      dispatch({
        type: GET_DATUM_FAILED,
        payload: { data: null, error: err }
      })
      if (failedFunc)
        failedFunc(err);
    }
  }
}

// // ** Add new Data
// export const addData = (data, callbackFunc, failedFunc) => {
//   return async dispatch => {
//     dispatch({
//       type: ADD_DATUM_FETCHING
//     })

//     try {
//       const response = await axios.post('/v1/firsttimermanagementgroup/add', data)       //.then(response => {             
//       dispatch({
//         type: ADD_DATUM_SUCCESS,
//         payload: { data: response.data }
//       })
//       if (callbackFunc) 
//         callbackFunc(data);

//       return Promise.resolve(response.data)
//     } catch (err) {
//       dispatch({
//         type: ADD_DATUM_FAILED,
//         payload: { data: null, error: err }
//       })
//       if (failedFunc)
//         failedFunc(errorMonitor);

//       return Promise.reject(err)
//     }
//   }
// }


// // ** Add new State
// export const updateData = (data, callbackFunc, failedFunc) => {
//   return async dispatch => {
//     dispatch({
//       type: UPDATE_DATUM_FETCHING
//     })
    
//     try {
//       const response = await axios.put(`/v1/firsttimermanagementgroup/${data.hid}`, {...data})       //.then(response => {
//       dispatch({
//         type: UPDATE_DATUM_SUCCESS,
//         payload: { data }
//       })   
//       if (callbackFunc) 
//         callbackFunc(data);

//       return Promise.resolve(data)
//     } catch (err) {
//       dispatch({
//         type: UPDATE_DATUM_FAILED,
//         payload: { data: null, error: err }
//       })
//       if (failedFunc)
//         failedFunc(err);

//       return Promise.reject(err)
//     }
//   }
// }

// ** Add new Data
export const addGroupMember = (data, callbackFunc, failedFunc) => {
  return async dispatch => {
    dispatch({
      type: ADD_DATUM_FETCHING
    })

    try {
      const response = await axios.post('/v1/firsttimermanagementgroup/seniorconvenantpartner/add', data)       //.then(response => {             
      dispatch({
        type: ADD_DATUM_SUCCESS,
        payload: { data: response.data }
      })
      if (callbackFunc) 
        callbackFunc(data);

      return Promise.resolve(response.data)
    } catch (err) {
      dispatch({
        type: ADD_DATUM_FAILED,
        payload: { data: null, error: err }
      })
      if (failedFunc)
        failedFunc(errorMonitor);

      return Promise.reject(err)
    }
  }
}

// ** Update new Group Member
export const updateGroupMember = (data, callbackFunc, failedFunc) => {
  return async dispatch => {
    dispatch({
      type: UPDATE_DATUM_FETCHING
    })
    
    try {
      const response = await axios.put(`/v1/firsttimermanagementgroup/seniorconvenantpartner/update/${data.tid}`, {...data})       //.then(response => {
      dispatch({
        type: UPDATE_DATUM_SUCCESS,
        payload: { data }
      })   
      if (callbackFunc) 
        callbackFunc(data);

      return Promise.resolve(data)
    } catch (err) {
      dispatch({
        type: UPDATE_DATUM_FAILED,
        payload: { data: null, error: err }
      })
      if (failedFunc)
        failedFunc(err);

      return Promise.reject(err)
    }
  }
}

// ** Add new State
export const updateTeamLead = (data, callbackFunc, failedFunc) => {
  return async dispatch => {
    dispatch({
      type: UPDATE_DATUM_FETCHING
    })
    
    try {
      const response = await axios.put(`/v1/firsttimermanagementgroup/update/teamlead/${data.hid}`, {...data})       //.then(response => {
      dispatch({
        type: UPDATE_DATUM_SUCCESS,
        payload: { data }
      })   
      if (callbackFunc) 
        callbackFunc(data);

      return Promise.resolve(data)
    } catch (err) {
      dispatch({
        type: UPDATE_DATUM_FAILED,
        payload: { data: null, error: err }
      })
      if (failedFunc)
        failedFunc(err);

      return Promise.reject(err)
    }
  }
}

// ** Remove team lead
export const removeTeamLead = (id, callbackFunc, failedFunc) => {
  return async dispatch => {
    dispatch({
      type: REMOVE_TEAM_LEAD_FETCHING
    })
    
    try {
      const response = await axios.delete(`/v1/firsttimermanagementgroup/remove/teamLead/${id}`) 
      dispatch({
        type: REMOVE_TEAM_LEAD_SUCCESS
      })

      if (callbackFunc) {
        callbackFunc();
      }

      return Promise.resolve(id)
    } catch (err) {
      dispatch({
        type: REMOVE_TEAM_LEAD_FAILED,
        payload: { data: null, error: err }
      })
      if (failedFunc)
        failedFunc(err);

      return Promise.reject(err)
    }
  }
}

// ** Remove member
export const removeMember = (id, callbackFunc, failedFunc) => {
  return async dispatch => {
    dispatch({
      type: REMOVE_MEMBER_FETCHING
    })
    
    try {
      const response = await axios.delete(`/v1/firsttimermanagementgroup/remove/SeniorConvenantPartner/${id}`)     //.then(response => {
      dispatch({
        type: REMOVE_MEMBER_SUCCESS
      })
      if (callbackFunc)
        callbackFunc();

      return Promise.resolve(id)
    } catch (err) {
      dispatch({
        type: REMOVE_MEMBER_FAILED,
        payload: { data: null, error: err }
      })
      if (failedFunc)
        failedFunc(err);

      return Promise.reject(err)
    }
  }
}

// ** Delete
export const deleteData = (id, callbackFunc, failedFunc) => {
  return async dispatch => {
    dispatch({
      type: DELETE_DATUM_FETCHING
    })
    
    try {
      const response = await axios.delete(`/v1/firsttimermanagementgroup/${id}`)     //.then(response => {
      dispatch({
        type: DELETE_DATUM_SUCCESS
      })
      if (callbackFunc)
        callbackFunc();

      return Promise.resolve(id)
    } catch (err) {
      dispatch({
        type: DELETE_DATUM_FAILED,
        payload: { data: null, error: err }
      })
      if (failedFunc)
        failedFunc(err);

      return Promise.reject(err)
    }
  }
}

export const deleteBulkData = (ids, callbackFunc, failedFunc) => {
  return async dispatch => {
    dispatch({
      type: DELETE_DATA_FETCHING
    })
    
    try {
      const response = await axios.post(`/v1/firsttimermanagementgroup/bulkDelete`, { ids })     //.then(response => {
      dispatch({
        type: DELETE_DATA_SUCCESS
      })
      if (callbackFunc)
        callbackFunc();

      return Promise.resolve(ids)
    } catch (err) {
      dispatch({
        type: DELETE_DATA_FAILED,
        payload: { data: null, error: err }
      })
      if (failedFunc)
        failedFunc(err);

      return Promise.reject(err)
    }
  }
}
