import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../../@jumbo/components/Common/formElements/AppTextInput';
// import CmtAvatar from '../../../../@coremat/CmtAvatar';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import CmtList from '../../../../../@coremat/CmtList';   // '../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import AppSelectBox from '../../../../../@jumbo/components/Common/formElements/AppSelectBox';
import { requiredMessage } from '../../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import LocationModel from '../../../../../components/LocationModel';
//import { isValidEmail } from '../../../../@jumbo/utils/commonHelper';
import { 
  getData,  
  addGroupMember,
  groupMemberSelectData
} from '../store/action';    


const useStyles = makeStyles(theme => ({
  exclusiveRoot: {
    fontSize: 12,
    color: theme.palette.error
  },
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
}));



const AddEditMemberDialog = ({ open, onCloseDialog, onAddSuccess, onAddFailed, onUpdateSuccess, onUpdateFailed }) => {
  const classes = useStyles();

  const { selectedItem, members, finish } = useSelector(({ ftmgmtgroups }) => ftmgmtgroups);
  //const memberStore = useSelector(state => state.members);

  const [groupNumber, setGroupNumber] = useState(selectedItem?.groupnumber)
  
  const [memberId, setMemberId] = useState(selectedItem?.teamlead?.id);
  const [memberIdError, setMemberIdError] = useState('');

  const [exclusiveError, setExclusiveError] = useState('');

  const [currentGroup, setCurrentGroup] = useState(selectedItem);

  const dispatch = useDispatch();

  useEffect(() => {

    setCurrentGroup(selectedItem);

    dispatch(groupMemberSelectData(selectedItem.tid, () => {
      // do nothing
    }, () => {
      // do nothing
    }))
  }, []);

  const onSubmitClick = () => {
    if (memberId) {
      onGroupSave();
    }
  };

  const onGroupSave = () => {
    if (currentGroup) {
      dispatch(
        addGroupMember({ groupid: currentGroup.tid, memberid: memberId, status: true }, () => {
          if (onUpdateSuccess)
            onUpdateSuccess();
          onCloseDialog();
        }, (err) => {
          if (onUpdateFailed)
            onUpdateFailed(err);
        }),
      );
    }
  };

  //console.log(selectedItem)

  return (
      <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot} fullWidth={true}>
        <DialogTitle className={classes.dialogTitleRoot}>{'Add Senior Covenant Partner'}</DialogTitle>
        <DialogContent dividers>
        {exclusiveError  && (
          <Box display="flex" alignItems="center" mb={{ xs: 6, md: 5 }}>
            <Typography className={classes.exclusiveRoot}>
              {groupNumber}
            </Typography>
          </Box>          
        )}
          {currentGroup && (
          <>
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
              <GridContainer>
                <Grid item xs={12} sm={12}>
                <AppSelectBox
                    fullWidth
                    variant="outlined"
                    label="Senior Covenant Partner"
                    value={memberId}
                    data={members}
                    valueKey="value"
                    labelKey="label"
                    onChange={e => {
                      setMemberId(e.target.value);
                      setMemberIdError('');
                      setExclusiveError('');
                    }}
                    helperText={memberIdError}
                  />
                </Grid>
              </GridContainer>
            </Box>
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
              <GridContainer>
                <Grid item xs={12} sm={12}>
                  <CmtList
                    data={selectedItem.members}
                    renderRow={(item, index) => (
                      <GridContainer style={{ marginBottom: 2 }} key={index}>
                        <Grid item xs={12} sm={12}>
                          <Box component="p">
                            {item.member?.title} {item.member?.lastname} {item.member?.firstname} {item.member?.othernames}
                          </Box>
                        </Grid>
                      </GridContainer>
                    )}
                  />
                </Grid>
              </GridContainer>
            </Box>
          </>
          )}

          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button onClick={onCloseDialog}>Cancel</Button>
            <Box ml={2}>
            <Button disabled={!finish} variant="contained" color="primary" onClick={onSubmitClick}>
                Save
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
  );
};

export default AddEditMemberDialog;

AddEditMemberDialog.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
