import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../../@jumbo/components/Common/formElements/AppTextInput';
import CmtAvatar from '../../../../../@coremat/CmtAvatar';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import CmtList from '../../../../../@coremat/CmtList';   // '../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import AppSelectBox from '../../../../../@jumbo/components/Common/formElements/AppSelectBox';
import AppDatePicker from '../../../../../@jumbo/components/Common/formElements/AppDatePicker';
import { emailNotValid, requiredMessage } from '../../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import { getSelectData as countrySelectData } from '../../../../../routes/app/settings/country/store/action';
import { getSelectDataById as stateSelectDataById } from '../../../../../routes/app/settings/state/store/action';
import { getSelectDataById as lgaSelectDataById } from '../../../../../routes/app/settings/lga/store/action';
import { ModeCommentOutlined } from '@material-ui/icons';

import { 
  updateAddress, 
} from '../store/action';  


const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
}));


const AddEditAddressDialog = ({ open, onCloseDialog, onAddSuccess, onAddFailed, onUpdateSuccess, onUpdateFailed }) => {
  const classes = useStyles();

  const { selectedItem, selectedAddress, finish } = useSelector(({ members }) => members);

  const [line, setLine] = useState(selectedAddress?.desc);
  const [lineError, setLineError] = useState('');

  const [landMark, setLandMark] = useState(selectedAddress?.landmark);
  const [landMarkError, setLandMarkError] = useState('');

  const countryStore = useSelector(state => state.countries);
  const stateStore = useSelector(state => state.states);
  const lgaStore = useSelector(state => state.lgas);

  const [town, setTown] = useState(selectedAddress?.location?.town);
  const [townError, setTownError] = useState('');
  
  const [lgaId, setLgaId] = useState(selectedAddress?.location?.lgaid);
  const [lgaError, setLgaError] = useState('');
  const [lgaName, setLgaName] = useState(selectedAddress?.location?.lga?.name);

  const [stateId, setStateId] = useState(selectedAddress?.location?.lga?.stateid);
  const [stateError, setStateError] = useState('');
  const [stateName, setStateName] = useState(selectedAddress?.location?.lga?.state?.name);

  const [countryId, setCountryId] = useState(selectedAddress?.location?.lga?.state?.countryid);
  const [countryError, setCountryError] = useState('');
  const [countryName, setCountryName] = useState(selectedAddress?.location?.lga?.state?.country?.name);

  const [currentAddress, setCurrentAddress] = useState(selectedAddress);

  const dispatch = useDispatch();


  useEffect(() => {

    dispatch(countrySelectData(() => {
      // do nothing
    }, () => {
      // do nothing
    }))

    dispatch(stateSelectDataById(countryId, () => {
      // do nothing
    }, () => {
      // do nothing
    }))

    dispatch(lgaSelectDataById(stateId, () => {
      // do nothing
    }, () => {
      // do nothing
    }))
  }, [countryId, stateId]);


  const onSubmitClick = () => {
    if (!line) {
      setLineError(requiredMessage);
    }
    if (!town) {
      setTownError(requiredMessage);
    }
    if (!lgaId) {
      setLgaError(requiredMessage);
    }
    if (!stateId) {
      setStateError(requiredMessage);
    }
    if (!countryId) {
      setCountryError(requiredMessage);
    }

    if (line && town && lgaId && stateId && countryId) {
      onAddressSave();
    }
  };

  const onAddressSave = () => {
    const newAddress = {
      desc: line,
      landmark: landMark,
      location: {
        town: town,
        lgaid: lgaId,
        lga: {
          id: lgaId,
          name: lgaName,
          stateid: stateId,
          state: {
            id: stateId,
            name: stateName,
            countryid: countryId,
            country: {
              id: countryId,
              name: countryName
            }
          }
        }
      }
    };

    if (currentAddress) {
      dispatch(
        updateAddress({...currentAddress, ...newAddress, hid: selectedItem.hid }, () => {
          if (onUpdateSuccess)
            onUpdateSuccess();
          onCloseDialog();
        }, (err) => {
          if (onUpdateFailed)
            onUpdateFailed(err);
        }),
      );
    } else {
      dispatch(
        updateAddress({hid: selectedItem.hid, ...newAddress }, () => {
          if (onAddSuccess)
            onAddSuccess();
          onCloseDialog();
        }, (err) => {
          if (onAddFailed)
            onAddFailed(err);
        }),
      );
    }
  };

  const fullName = `${selectedItem?.lastname} ${selectedItem?.firstname} ${selectedItem?.othernames ? selectedItem?.othernames : ""}  (${selectedItem?.memberid})`;
  return (
      <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot} fullWidth={true}>
        <DialogTitle className={classes.dialogTitleRoot}>{currentAddress ? 'Edit Address' : 'New Address'}</DialogTitle>
        <DialogContent dividers>
        {fullName && (
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <Box component="span" fontSize={16} ml={3}>
                  {fullName}
                </Box>
              </Grid>
            </GridContainer>
          </Box>
        )}
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
        <GridContainer>
            <Grid item xs={12} sm={12}>
            <AppTextInput
                fullWidth
                variant="outlined"
                label="Address Line *"
                value={line}
                onChange={e => {
                setLine(e.target.value);
                setLineError('');
                }}
                helperText={lineError}
            />
            </Grid>          
        </GridContainer>
        </Box>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
        <GridContainer>
            <Grid item xs={12} sm={12}>
            <AppTextInput
                fullWidth
                variant="outlined"
                label="LandMark"
                value={landMark}
                onChange={e => {
                setLandMark(e.target.value);
                setLandMarkError('');
                }}
                helperText={landMarkError}
            />
            </Grid>          
        </GridContainer>
        </Box>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
        <GridContainer>
            <Grid item xs={12} sm={12}>
            <AppTextInput
                fullWidth
                variant="outlined"
                label="Town"
                value={town}
                onChange={e => {
                setTown(e.target.value);
                setTownError('');
                }}
                helperText={townError}
            />
            </Grid>          
        </GridContainer>
        </Box>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
        <GridContainer>
            <Grid item xs={12} sm={12}>
            <AppSelectBox
                fullWidth
                variant="outlined"
                label="Country"
                value={countryId}
                data={countryStore?.items}
                valueKey="value"
                labelKey="label"
                onChange={e => {
                var text = e.nativeEvent.target.innerText;
                setCountryId(e.target.value);
                setCountryName(text);
                }}
                helperText={countryError}
            />
            </Grid>
        </GridContainer>
        </Box>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
        <GridContainer>
            <Grid item xs={12} sm={12}>
            <AppSelectBox
                fullWidth
                variant="outlined"
                label="State"
                value={stateId}
                data={stateStore?.items}
                valueKey="value"
                labelKey="label"
                onChange={e => {
                var text = e.nativeEvent.target.innerText;
                setStateId(e.target.value);
                setStateName(text);
                }}
                helperText={stateError}
            />
            </Grid>
        </GridContainer>
        </Box>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
        <GridContainer>
            <Grid item xs={12} sm={12}>
            <AppSelectBox
                fullWidth
                variant="outlined"
                label="Lga"
                value={lgaId}
                data={lgaStore?.items}
                valueKey="value"
                labelKey="label"
                onChange={e => {
                var text = e.nativeEvent.target.innerText;
                setLgaId(e.target.value);
                setLgaName(text);
                }}
                helperText={lgaError}
            />
            </Grid>
        </GridContainer>
        </Box> 

          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button onClick={onCloseDialog}>Cancel</Button>
            <Box ml={2}>
            <Button disabled={!finish} variant="contained" color="primary" onClick={onSubmitClick}>
                Save
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
  );
};

export default AddEditAddressDialog;

AddEditAddressDialog.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
