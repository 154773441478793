import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom'
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../../../@jumbo/components/Common/formElements/AppTextInput';
// import CmtAvatar from '../../../../@coremat/CmtAvatar';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import CmtList from '../../../../../@coremat/CmtList';   // '../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import AppSelectBox from '../../../../../@jumbo/components/Common/formElements/AppSelectBox';
import AppDatePicker from '../../../../../@jumbo/components/Common/formElements/AppDatePicker';
import { requiredMessage } from '../../../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import { isValidEmail } from '../../../../../@jumbo/utils/commonHelper';
import { getData, addData, updateData } from '../store/action';    
import { getSelectData as countrySelectData } from '../../../settings/country/store/action';
import { getSelectData as categorySelectData } from '../../jobCategory/store/action';
import { getSelectData as companySelectData } from '../../jobCompany/store/action';
import { getSelectData as currencySelectData } from '../../../settings/currency/store/action';
import { setForgetPassMailSent } from '../../../../../redux/actions/Auth';
import ChipInput from 'material-ui-chip-input'

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
}));



const AddEditDialog = ({ open, onCloseDialog, onAddSuccess, onAddFailed, onUpdateSuccess, onUpdateFailed }) => {
  const classes = useStyles();

  const { selectedItem, finish } = useSelector(({ jobs }) => jobs);
  const countryStore = useSelector(state => state.countries);
  const jobCategoryStore = useSelector(state => state.jobCategories);
  const jobCompanyStore = useSelector(state => state.jobCompanies);
  const currencyStore = useSelector(state => state.currencies);

  const [title, setTitle] = useState(selectedItem?.title);
  const [titleError, setTitleError] = useState('');

  const [description, setDescription] = useState(selectedItem?.description);
  const [descriptionError, setDescriptionError] = useState('');
  
  const [email, setEmail] = useState(selectedItem?.applicationemail);
  const [emailError, setEmailError] = useState('');
  
  const [website, setWebsite] = useState(selectedItem?.applicationwebsite);
  const [websiteError, setWebsiteError] = useState('');
  
  const [companyId, setCompanyId] = useState(selectedItem?.jobcompanyid);
  const [companyError, setCompanyError] = useState('');

  const [categoryId, setCategoryId] = useState(selectedItem?.categoryid);
  const [categoryError, setCategoryError] = useState('');

  const [location, setLocation] = useState(selectedItem?.location);
  const [locationError, setLocationError] = useState('');

  const [countryId, setCountryId] = useState(selectedItem?.countryid);
  const [countryError, setCountryError] = useState('');

  const [tags, setTags] = useState(selectedItem?.jtags ?? []);
  const [tagsError, setTagsError] = useState('');
 
  const [appStartDate, setAppStartDate] = useState(selectedItem?.start_date ?? null)
  const [appStartDateError, setAppStartDateError] = useState('');

  const [appCloseDate, setAppCloseDate] = useState(selectedItem?.last_date ?? null)
  const [appCloseDateError, setAppCloseDateError] = useState('');

  const [currencyId, setCurrencyId] = useState(selectedItem?.currencyid);
  const [currencyError, setCurrencyError] = useState('');
  
  const [salary, setSalary] = useState(selectedItem?.salary)
  const [salaryError, setSalaryError] = useState('');
    
  const [maxSalary, setMaxSalary] = useState(selectedItem?.max_salary)
  const [maxSalaryError, setMaxSalaryError] = useState('');

  const [currentJob, setCurrentJob] = useState(selectedItem);
  
  const start_max_date = moment().add(1, 'month').toDate();
  const start_min_date = moment().add(-6, 'month').toDate();

  const close_max_date = moment().add(1, 'year').toDate();
  const close_min_date = moment().add(1, 'day').toDate();


  const dispatch = useDispatch();

  useEffect(() => {

    setCurrentJob(selectedItem)

    dispatch(countrySelectData(() => {
      // do nothing
    }, () => {
      // do nothing
    }))
    
    dispatch(categorySelectData(() => {
      // do nothing
    }, () => {
      // do nothing
    }))
   
    dispatch(companySelectData(() => {
      // do nothing
    }, () => {
      // do nothing
    }))
       
    dispatch(currencySelectData(() => {
      // do nothing
    }, () => {
      // do nothing
    }))
  }, []);

  const onSubmitClick = () => {
    if (!title) {
      setTitleError(requiredMessage);
    }   
    if (!description) {
      setDescriptionError(requiredMessage);
    } 
    if (!location) {
      setLocationError(requiredMessage);
    } 
    if (!countryId) {
      setCountryError(requiredMessage);
    } 
    if (!categoryId) {
      setCategoryError(requiredMessage);
    } 
    if (!tags) {
      setTagsError("Enter atleast one tag!");
    }

    if (title && description && location && countryId && categoryId && tags) {
      onJobSave();
    }
  };

  const onJobSave = () => {
    const newJob = {
      title: title,
      description: description,
      location: location,
      countryId: parseInt(countryId),
      categoryId: parseInt(categoryId),
      jobcompanyId: parseInt(companyId),
      applicationemail: email,
      applicationwebsite: website,
      start_date: appStartDate,
      last_date: appCloseDate,
      salary: parseFloat(salary),
      max_salary: parseFloat(maxSalary),
      currencyId: parseInt(currencyId),
      jtags: tags //[tags.length - 1]
    };

    console.log('current', currentJob);
    console.log('new', newJob);

    if (currentJob) {
      dispatch(
        updateData({ ...currentJob, ...newJob }, () => {
          if (onUpdateSuccess)
            onUpdateSuccess();
          //createNotification("success", "Updated Successfully!");
          onCloseDialog();
        }, (err) => {
          if (onUpdateFailed)
            onUpdateFailed(err);
          //createNotification('error', `Could not update!,\n${err}`);
        }),
      );
    } else {
      dispatch(
        addData(newJob, () => {
          if (onAddSuccess)
            onAddSuccess();
          //createNotification("success", "Created Successfully!");
          onCloseDialog();
        }, (err) => {
          if (onAddFailed)
            onAddFailed(err);
          //createNotification('error', `Could not create!,\n${err}`);
        }),
      );
    }
  };
  
  const onBeforeAdd = (chip) => {
    return chip.length >= 3
  }

  const handleAdd = (tag) => {
    setTags([...tags, tag])
  }

  const handleDelete = (deletedChip) => {
    setTags(tags.filter(c => c != deletedChip))
  }

  return (
      <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot} fullWidth={true}>
        <DialogTitle className={classes.dialogTitleRoot}>{currentJob ? 'Edit Job' : 'Create New Job'}</DialogTitle>
        <DialogContent dividers>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AppTextInput
                  fullWidth
                  variant="outlined"
                  label="Title"
                  value={title}
                  onChange={e => {
                    setTitle(e.target.value);
                    setTitleError('');
                  }}
                  helperText={titleError}
                />
              </Grid>
            </GridContainer>
          </Box>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AppTextInput
                  fullWidth
                  multiline={true}
                  rows={5}
                  variant="outlined"
                  label="Description"
                  value={description}
                  onChange={e => {
                    setDescription(e.target.value);
                    setDescriptionError('');
                  }}
                  helperText={descriptionError}
                />
              </Grid>
            </GridContainer>
          </Box>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AppSelectBox
                  fullWidth
                  variant="outlined"
                  label="Company"
                  value={companyId}
                  data={jobCompanyStore?.items}
                  valueKey="value"
                  labelKey="label"
                  onChange={e => {
                    setCompanyId(e.target.value);
                    setCompanyError('');
                  }}
                  helperText={companyError}
                />
              </Grid>
            </GridContainer>
          </Box>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AppTextInput
                  fullWidth
                  variant="outlined"
                  label="Application Email"
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value);
                    setEmailError('');
                  }}
                  helperText={emailError}
                />
              </Grid>
            </GridContainer>
          </Box>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AppTextInput
                  fullWidth
                  variant="outlined"
                  label="Application Website"
                  value={website}
                  onChange={e => {
                    setWebsite(e.target.value);
                    setWebsiteError('');
                  }}
                  helperText={websiteError}
                />
              </Grid>
            </GridContainer>
          </Box>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AppSelectBox
                  fullWidth
                  variant="outlined"
                  label="Job Category"
                  value={categoryId}
                  data={jobCategoryStore?.items}
                  valueKey="value"
                  labelKey="label"
                  onChange={e => {
                    setCategoryId(e.target.value);
                    setCategoryError('');
                  }}
                  helperText={categoryError}
                />
              </Grid>
            </GridContainer>
          </Box>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <ChipInput
                  value={tags}
                  onBeforeAdd={(chip) => onBeforeAdd(chip)}
                  onAdd={(chip) => handleAdd(chip)}
                  onDelete={(deletedChip) => handleDelete(deletedChip)}
                  onChange={(chip) => handleAdd(chip)}
                  // onBlur={(event) => {
                  //   if (event.target.value) {
                  //       handleAdd(event.target.value)
                  //   }
                  // }}
                  variant="outlined"
                  fullWidth
                  label='Tags'
                  helperText={tagsError}
                />
              </Grid>
            </GridContainer>
          </Box>  
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AppTextInput
                  fullWidth
                  variant="outlined"
                  label="Location"
                  value={location}
                  onChange={e => {
                    setLocation(e.target.value);
                    setLocationError('');
                  }}
                  helperText={locationError}
                />
              </Grid>
            </GridContainer>
          </Box>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={12}>
                <AppSelectBox
                  fullWidth
                  variant="outlined"
                  label="Country"
                  value={countryId}
                  data={countryStore?.items}
                  valueKey="value"
                  labelKey="label"
                  onChange={e => {
                    setCountryId(e.target.value);
                    setCountryError('');
                  }}
                  helperText={countryError}
                />
              </Grid>
            </GridContainer>
          </Box>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={6}>
                <AppDatePicker
                      fullWidth
                      inputVariant="outlined"
                      format='DD-MM-YYYY'
                      minDate={start_min_date}
                      maxDate={start_max_date}
                      label="Application Begins"
                      value={appStartDate}
                      onChange={date => {
                        setAppStartDate(date.format("YYYY-MM-DD"));
                        setAppStartDateError('');
                      }}
                      helperText={appStartDateError}
                  />
              </Grid>
              <Grid item xs={12} sm={6}>
                <AppDatePicker
                      fullWidth
                      inputVariant="outlined"
                      format='DD-MM-YYYY'
                      minDate={close_min_date}
                      maxDate={close_max_date}
                      label="Application Closes"
                      value={appCloseDate}
                      onChange={date => {
                        setAppCloseDate(date.format("YYYY-MM-DD"));
                        setAppCloseDateError('');
                      }}
                      helperText={appCloseDateError}
                  />
              </Grid>
            </GridContainer>
          </Box>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
            <GridContainer>
              <Grid item xs={12} sm={4}>
                <AppSelectBox
                  fullWidth
                  variant="outlined"
                  label="Currency"
                  value={currencyId}
                  data={currencyStore?.items}
                  valueKey="value"
                  labelKey="label"
                  onChange={e => {
                    setCurrencyId(e.target.value);
                    setCurrencyError('');
                  }}
                  helperText={currencyError}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <NumberFormat
                  fullWidth
                  variant="outlined"
                  label="Salary"
                  thousandSeparator={true}
                  value={salary}
                  customInput={AppTextInput}
                  type="text"
                  onValueChange={({ formattedValue, value }) => {
                    setSalary(value);
                    setSalaryError('');
                  }}
                  helperText={salaryError}
                />
                {/* <AppTextInput
                  fullWidth
                  variant="outlined"
                  label="Salary"
                  value={salary}
                  onChange={e => {
                    setSalary(e.target.value);
                    setSalaryError('');
                  }}
                  helperText={salaryError}
                /> */}
              </Grid> 
              <Grid item xs={12} sm={4}>
                <NumberFormat
                  fullWidth
                  variant="outlined"
                  label="Max Salary"
                  thousandSeparator={true}
                  helperText={maxSalaryError}
                  value={maxSalary}
                  customInput={AppTextInput}
                  type="text"
                  onValueChange={({ formattedValue, value }) => {
                    setMaxSalary(value);
                    setMaxSalaryError('');
                  }}
                />
                {/* <AppTextInput
                  fullWidth
                  variant="outlined"
                  label="MaxSalary"
                  value={maxSalary}
                  onChange={e => {
                    setMaxSalary(e.target.value);
                    setMaxSalaryError('');
                  }}
                  helperText={maxSalaryError}
                /> */}
              </Grid>              
            </GridContainer>
          </Box>

          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button onClick={onCloseDialog}>Cancel</Button>
            <Box ml={2}>
            <Button disabled={!finish} variant="contained" color="primary" onClick={onSubmitClick}>
                Save
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
  );
};

export default AddEditDialog;

AddEditDialog.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
