//import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../../../../../services/auth/jwt/config';   // 'axios'

import { 
    GET_USER_DETAIL_FETCHING, 
    GET_USER_DETAIL_SUCCESS, 
    GET_USER_DETAIL_FAILED 
} from '../type';

export const getUserDetail = () => {
  return async dispatch => {
    dispatch({
      type: GET_USER_DETAIL_FETCHING
    })

    try {
        const response = await axios.get('/v1/my/profile')
        dispatch({
          type: GET_USER_DETAIL_SUCCESS,
          payload: {
            item: response.data
          }
        })
  
        return Promise.resolve(response.data)
      } catch (err) {
        dispatch({
          type: GET_USER_DETAIL_FAILED,
          payload: { item: null, error: err }
        })
  
        return Promise.reject(err)
      }
  };
};