
export const SET_DATUM_DETAILS = 'SET_JOB_CATEGORY_DETAILS'

export const GET_DATA_FETCHING = 'GET_JOB_CATEGORIES_FETCHING'
export const GET_ALL_DATA_SUCCESS = 'GET_ALL_JOB_CATEGORIES_SUCCESS'
export const GET_PAGED_DATA_SUCCESS = 'GET_PAGED_JOB_CATEGORIES_SUCCESS'
export const GET_DATA_FAILED = 'GET_JOB_CATEGORIES_FAILED'

export const GET_SELECT_LIST_DATA_FETCHING = 'GET_SELECT_LIST_JOB_CATEGORIES_FETCHING'
export const GET_SELECT_LIST_DATA_SUCCESS = 'GET_SELECT_LIST_JOB_CATEGORIES_SUCCESS'
export const GET_SELECT_LIST_DATA_FAILED = 'GET_SELECT_LIST_JOB_CATEGORIES_FAILED'

export const GET_DATUM_FETCHING = 'GET_JOB_CATEGORY_FETCHING'
export const GET_DATUM_SUCCESS = 'GET_JOB_CATEGORY_SUCCESS'
export const GET_DATUM_FAILED = 'GET_JOB_CATEGORY_FAILED'

export const ADD_DATUM_FETCHING = 'ADD_JOB_CATEGORY_FETCHING'
export const ADD_DATUM_SUCCESS = 'ADD_JOB_CATEGORY_SUCCESS'
export const ADD_DATUM_FAILED = 'ADD_JOB_CATEGORY_FAILED'

export const UPDATE_DATUM_FETCHING = 'UPDATE_JOB_CATEGORY_FETCHING'
export const UPDATE_DATUM_SUCCESS = 'UPDATE_JOB_CATEGORY_SUCCESS'
export const UPDATE_DATUM_FAILED = 'UPDATE_JOB_CATEGORY_FAILED'

export const DELETE_DATUM_FETCHING = 'DELETE_JOB_CATEGORY_FETCHING'
export const DELETE_DATUM_SUCCESS = 'DELETE_JOB_CATEGORY_SUCCESS'
export const DELETE_DATUM_FAILED = 'DELETE_JOB_CATEGORY_FAILED'

export const DELETE_DATA_FETCHING = 'DELETE_JOB_CATEGORIES_FETCHING'
export const DELETE_DATA_SUCCESS = 'DELETE_JOB_CATEGORIES_SUCCESS'
export const DELETE_DATA_FAILED = 'DELETE_JOB_CATEGORIES_FAILED'
