// ** Initial State
import { GET_COUNT_FETCHING, GET_COUNT_SUCCESS, GET_COUNT_FAILED } from '../type';

const initialState = {
  count: null,
  isCountLoading: false,
  countError: null,
};

const currentweekcard = (state = initialState, action) => {
  switch (action.type) {
    case GET_COUNT_FETCHING:
      return { ...state, isCountLoading: true, countError: null };
    case GET_COUNT_SUCCESS:
      return {
        ...state,
        count: action.payload.data,
        isCountLoading: false,
        countError: null,
      };
    case GET_COUNT_FAILED:
      return {
        ...state,
        isCountLoading: false,
        countError: action.payload.error,
      };
    default:
      return { ...state };
  }
};
export default currentweekcard;
